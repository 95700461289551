import React, { useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import SummarizeIcon from '@mui/icons-material/Summarize';
import StepWizard from "react-step-wizard";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Row, Col, Button, CardBody, Card, Container } from "reactstrap";
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../services/Api';
import { useAuthHeader } from 'react-auth-kit';
import { Check, SupervisedUserCircleOutlined } from "@mui/icons-material";
import MyAlert from "../../utils/MyAlert";
import { useNavigate } from 'react-router-dom';

const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div>
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <IconButton color="success" onClick={handleBack}>
              <ArrowBackIosNewIcon/>
            </IconButton>
          </Col>
        )}
        <Col className="text-end">
          {props.currentStep < props.totalSteps && (
            <IconButton color="success" onClick={handleNext}>
              <ArrowForwardIosIcon/>
            </IconButton>
          )}
          {props.currentStep === props.totalSteps && (
            <Button className="bg-success" onClick={handleFinish}>Guardar</Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const One = (props) => {

  const types = [
    {id : '1', name : 'Livro'},
    {id : '2', name : 'Secção de Livro'},
    {id : '3', name : 'Artigo de Jornal'},
    {id : '4', name : 'Outro'},
  ]

  const [title, setTitle] = useState({});
  const [itemTitle, setItemTitle] = useState({});
  const [type, setType] = useState({});
  const [otherType, setOtherType] = useState({});
  const [publisher, setPublisher] = useState({});
  const [datePublished, setDatePublished] = useState({});
  const [edition, setEdition] = useState({});
  const [place, setPlace] = useState({});
  const [pages, setPages] = useState({});
  const [link, setLink] = useState({});
  const [keywords, setKeywords] = useState({});
  const [citation, setCitation] = useState({});
  const [peerReview, setPeerReview] = useState({});
  const [code, setCode] = useState({});


  const [error, setError] = useState("");

  const onTitleChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setTitle((title) => ({
      ...title,
      [targetName]: targetValue
    }));
  };

  const onTypeChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setType((type) => ({
      ...type,
      [targetName]: targetValue
    }));
  };

  const onOtherTypeChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setOtherType((otherType) => ({
      ...otherType,
      [targetName]: targetValue
    }));
  };

  const onItemTitleChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setItemTitle((itemTitle) => ({
      ...itemTitle,
      [targetName]: targetValue
    }));
  };

  const onPublisherChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setPublisher((publisher) => ({
      ...publisher,
      [targetName]: targetValue
    }));
  };

  const onDateChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setDatePublished((datePublished) => ({
      ...datePublished,
      [targetName]: targetValue
    }));
  };

  const onEditionChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setEdition((edition) => ({
      ...edition,
      [targetName]: targetValue
    }));
  };

  const onPlaceChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setPlace((place) => ({
      ...place,
      [targetName]: targetValue
    }));
  };

  const onPagesChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setPages((pages) => ({
      ...pages,
      [targetName]: targetValue
    }));
  };

  const onLinkChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setLink((link) => ({
      ...link,
      [targetName]: targetValue
    }));
  };

  const onKeywordsChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setKeywords((keywords) => ({
      ...keywords,
      [targetName]: targetValue
    }));
  };

  const onCitationChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setCitation((citation) => ({
      ...citation,
      [targetName]: targetValue
    }));
  };

  const onPeerChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setPeerReview((peerReview) => ({
      ...peerReview,
      [targetName]: targetValue
    }));
  };

  const onCodeChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setCode((code) => ({
      ...code,
      [targetName]: targetValue
    }));
  };

  const validate = () => {
    if (!title.title || !type.type || !datePublished.datePublished) 
      setError("Titulo, tipo e data de publicação são campos obrigatórios");
    else {
      setError("");
      props.nextStep();
      props.userCallback(title);
      props.userCallback(itemTitle);
      props.userCallback(type);
      props.userCallback(otherType)
      props.userCallback(publisher);
      props.userCallback(datePublished);
      props.userCallback(edition);
      props.userCallback(place);
      props.userCallback(pages);
      props.userCallback(link);
      props.userCallback(keywords);
      props.userCallback(citation);
      props.userCallback(peerReview);
      props.userCallback(code);
    }
  };

  return (
    <Card>
      <CardBody className='p-3'>
        <Row>
          <span style={{ color: "red" }}>{error}</span>
          <Col>
            <TextField 
              fullWidth
              label="Título" 
              variant="standard" 
              name="title"
              required
              onChange={onTitleChange}
            />
          </Col>
        </Row>
        <Row className='my-3'>
          <Col className='mb-3'>
            <FormControl fullWidth variant="standard">
              <InputLabel>Tipo de Obra/Artigo</InputLabel>
              <Select
                label="Tipo de Obra/Artigo"
                onChange={onTypeChange}
                name="type"
              >
                {
                  types.map((type, index) => (
                    <MenuItem value={type.name} id={index}>
                      {type.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Col>
          <Col className={type.type !== "Outro" ? "d-none" : ""}>
            <TextField 
              fullWidth
              label="Escreva o tipo" 
              variant="standard" 
              name="otherType"
              onChange={onOtherTypeChange}
            />
          </Col>
          <Col className={type.type === "Secção de Livro" || type.type === "Artigo de Jornal" ? "" : "d-none"}>
            <TextField 
              fullWidth
              label={ type.type === "Secção de Livro" ? "Titulo do Livro" : type.type === "Artigo de Jornal" ? "Nome do Jornal" : ""} 
              variant="standard" 
              name="itemTitle"
              onChange={onItemTitleChange}
            />
          </Col>
          <Col className={type.type !== "Livro" ? "d-none" : ""}>
            <TextField 
              fullWidth
              label="Editora" 
              variant="standard" 
              name="publisher"
              onChange={onPublisherChange}
            />
          </Col>
          <Col>
            <InputLabel>Data de Publicação</InputLabel>
            <TextField
              required
              fullWidth 
              type={'date'} 
              variant="standard"
              name="datePublished" 
              onChange={onDateChange}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col className={type.type !== "Livro" ? "d-none" : ""}>
            <TextField 
              fullWidth type={'number'} 
              label="Edição" 
              variant="standard"
              name="edition" 
              onChange={onEditionChange}
            />
          </Col>
          <Col className={type.type !== "Livro" ? "d-none" : ""}>
            <TextField 
              fullWidth
              label="Local de publicação" 
              variant="standard"
              name="place" 
              onChange={onPlaceChange}
            />
          </Col>
          <Col >
            <TextField 
              type="number"
              fullWidth
              required
              label="Numero de páginas" 
              variant="standard"
              name="pages" 
              onChange={onPagesChange}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={10}>
            <TextField
              label="Citação" 
              variant="standard" 
              fullWidth 
              name="citation"
              onChange={onCitationChange}
            />
          </Col>
          <Col md={2}>
            <FormGroup>
              <FormControlLabel 
                control={
                  <Checkbox
                    name="peerReview"
                    onChange={onPeerChange}
                  />
                } 
                label="Revisão por pares" 
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              type="textarea"
              label="Palavras Chave" 
              variant="standard" 
              fullWidth 
              name="keywords"
              onChange={onKeywordsChange}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={8} >
            <TextField 
              fullWidth
              label="Link" 
              variant="standard"
              name="link" 
              onChange={onLinkChange}
            />
          </Col>
          <Col sm={4} >
            <TextField 
              fullWidth
              label="Código da Obra/Artigo" 
              variant="standard"
              name="code" 
              onChange={onCodeChange}
            />
          </Col>
        </Row>
        <Row>
          <ActionButtons {...props} nextStep={validate} />
        </Row>
      </CardBody>
    </Card>
  );
};

const Two = (props) => {

  const [error, setError] = useState("");

  const [authorInputs, setAuthorInputs] = useState([
    { author: null, involvement: "" },
  ]);

  const handleAuthorChange = (event, newValue, index) => {
    const updatedAuthorInputs = [...authorInputs];
    updatedAuthorInputs[index].author = newValue;
    setAuthorInputs(updatedAuthorInputs);
  };

  const handleInvolvementChange = (event, index) => {
    const value = event.target.value;
    const updatedAuthorInputs = [...authorInputs];
    updatedAuthorInputs[index].involvement = value;
    setAuthorInputs(updatedAuthorInputs);
  };

  const handleAddAuthor = () => {
    setAuthorInputs([...authorInputs, { author: null, involvement: "" }]);
  };

  const validate2 = () => {
    if(!authorInputs){
      setError("Os campos são obrigatórios!")
    }else{
      setError("");
      props.nextStep();
      props.userCallback({authors: authorInputs});
    }
  };

  return (
    <div>
      <span style={{ color: "red" }}>{error}</span>
      <Card>
        <CardBody className='p-3'>
          {authorInputs.map((input, index) => (
            <div key={index}>
              <Row className='mb-3'>
                <Col>
                  <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
                  <Autocomplete
                    value={input.author}
                    onChange={(event, newValue) => handleAuthorChange(event, newValue, index)}
                    options={props.autors}
                    getOptionLabel={(option) => option.name+" "+option.last_name+', '+option.orcid}
                    renderInput={(params) => (
                      <TextField {...params} label="Autor" variant="standard" />
                    )}
                  />
                    </FormControl>
                </Col>
              </Row>
              <Row className='mb-3'>
              <Col>
                <TextField 
                  fullWidth
                  label="Envolvimento" 
                  variant="standard"
                  name="involvement" 
                  value={input.involvement}
                  onChange={(event) => handleInvolvementChange(event, index)}
                />
              </Col>
              </Row>
            </div>
          ))}
          <Row className='mb-3'>
            <Col>
              <div>
                <Button onClick={handleAddAuthor}>Adicionar Mais Autores</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ActionButtons {...props} nextStep={validate2} />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const Three = (props) => {

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <Container>
      <Card>
        <CardBody className="p-3 m-3">
          <h3>Confirme os dados</h3>
          <br />
          <Row>
            <h4>Dados da Obra/Artigo</h4>
            <Row>
              <Col>
                Titulo : <strong>{props.user.title+", "+props.user.itemTitle}</strong>
              </Col>
            </Row>
            <br />
            <h4>Autor(es)</h4>
            {
              props.user?.authors?.map((author) => (
                <Row>
                  <Col>
                    Autor : <strong>{author.author.name}</strong>
                  </Col>
                  <Col>
                    Envolvimento : <strong>{author.involvement}</strong>
                  </Col>
                </Row>
              ))
            }
            <Row>
              <Col className={props.user?.publisher ? "" : "d-none"}>
                Editora : <strong>{props.user.publisher}</strong>
              </Col>
              <Col>
                Data De Publicação : <strong>{props.user.datePublished}</strong>
              </Col>
              <Col className={props.user?.edition ? "" : "d-none"}>
                Edição : <strong>{props.user.edition}</strong>
              </Col>
            </Row>
            <Row>
              <Col className={props.user?.place ? "" : "d-none"}>
                Local de publicação : <strong>{props.user.place}</strong>
              </Col>
              <Col>
                Número de páginas : <strong>{props.user.pages}</strong>
              </Col>
              <Col className={props.user?.link ? "" : "d-none"}>
                Link: <strong> <a href={props.user.link}>{props.user.link}</a></strong>
              </Col>
            </Row>
            <Row>
              <Col className={props.user?.keywords ? "" : "d-none"}>
                Palavras Chave : <strong>{props.user.keywords}</strong>
              </Col>
              <Col>
                Revisão por Pares : <strong>{props.user.peerReview ? "Revisado" : "Não revisado"}</strong>
              </Col>
            </Row>
            <Row>
              <Col className={props.user?.citation ? "" : "d-none"}>
                Citação : <strong>{props.user.citation}</strong>
              </Col>
              <Col className={props.user?.code ? "" : "d-none"}>
                Codigo : <strong>{props.user.code}</strong>
              </Col>
            </Row>
          </Row>
        </CardBody>
      </Card>
    <br />
    <ActionButtons {...props} lastStep={handleLastStep} />
    </Container>
  );
}

const ArticleStepper = () => {

  const auth = useAuthHeader()

  const [autors, setAutors] = useState([]);

  const getAutors = async () => {
    
    const res = await api.get(`autors`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setAutors(res.data.autors)
  }

  useEffect(() => {
    getAutors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  const [, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignUser = (val) => {
    setUser((user) => ({
      ...user,
      ...val
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleComplete = async () => {
    setLoading(true)
    try {
      await api.post('article', {
        title: user.title+", "+user.itemTitle,
        type: user.type === "Outro" ? user.otherType : user.type,
        place: user.place,
        publisher: user.publisher,
        date_published: user.datePublished,
        edition:user.edition,
        pages_number: user.pages,
        link: user.link,
        keywords: user.keywords,
        citation: user.citation,
        peer_review: user.peerReview ? true : false,
        code: user.code,
        autors :
          user?.authors?.map((author) => (
            {
              id: parseInt(author.author.id),
              involvement: author.involvement
            }
          )),
      },
      {
        headers: {
          Authorization: auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      navigate('/articles')
      setLoading(false)
      setMessage("Artigo/Obra Adicionado(a) com Sucesso");
      setShowAlert(true);
    }catch(error){
      setLoading(false);
      if(error.response){
        setMessage("Erro ao tentar Salvar");
        setShowAlert(true);
      }
    }
  };

  return (
   <>
    {
        loading ? (
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
          </Container>
        ) : (
          <Container>
            <MyAlert
              showAlert={showAlert}
              handleCloseAlert={handleCloseAlert}
              message={message}
            />
            <Stepper activeStep={activeStep}>
              <Step label="Dados" children={<SummarizeIcon />} className={activeStep === 0 ? "bg-success" : ""} />
              <Step label="Autores" children={<SupervisedUserCircleOutlined />} className={activeStep === 1 ? "bg-success" : ""} />
              <Step label="Confirmação" children={<Check/>} className={activeStep === 2 ? "bg-success" : ""} />
            </Stepper>
            <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
              <One userCallback={assignUser}/>
              <Two user={user} userCallback={assignUser} autors={autors}/>
              <Three user={user} completeCallback={handleComplete}/>
            </StepWizard>
          </Container>
        )
    }
   </>
  );
};

export default ArticleStepper;
