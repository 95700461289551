import React, {useState} from 'react';
import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarFooter,
} from 'cdbreact';
import SidebarItem from './SideComponents/SidebarItem';
import { useAuthUser } from 'react-auth-kit';

const Sidebar = () => {
  
  const user = useAuthUser();

  const adminSidebarItems = [
    { id: 1, iname: "home", text: "Página Inicial", link: "/" },
    { id:1 ,iname: "fas fa-dice-one", text: "Unidades", link: "unities" },
    { id: 3, iname: "chalkboard", text: "Cursos", link: "courses" },
    { id: 6, iname: "fas fa-cog", text: "Gestão de Usuários", link: "users" },
  ];

  const commonSidebarItems = [
    { id:1 ,iname: "home", text: "Página Inicial", link: "/"},
    { id:1 ,iname: "fas fa-suitcase", text: "Projectos", link: "projects", 
    subItems : [
      { id:1 ,iname: "fas fa-dice-one", text: "Unidades", link: "unities" },
      { id:2 ,iname: "fas fa-money-bill", text: "Financiadores", link: "financiers" },
      { id:3 ,iname: "fas fa-handshake", text: "Parceiros", link: "partners" },
    ] },
    { id:2 ,iname: "fas fa-book", text: "Obras/Artigos", link: "articles" , 
    subItems : [
      { id:1 ,iname: "fa-sharp fa-regular fa-users", text: "Autores", link: "autors" },
    ] },
    { id:3 ,iname: "fas fa-calendar", text: "Eventos", link: "events", 
    subItems : [
      { id:1 ,iname: "fas fa-dice-one", text: "Unidades", link: "unities" },
      { id:2 ,iname: "fas fa-handshake", text: "Parceiros", link: "partners" },
    ] },
    { id:4 ,iname: "fas fa-graduation-cap", text: "Trabalhos(TCC)", link: "works", 
    subItems : [
      { id:1 ,iname: "user-friends", text: "Estudantes", link: "students" },
      { id:2 ,iname: "fa-solid fa-user-tie", text: "Supervisores", link: "supervisors" },
      { id:3 ,iname: "chalkboard", text: "Cursos", link: "courses" },
    ] },
    { id:5 ,iname: "fas fa-file", text: "Relatórios", link: "reports" },
  ];

  const [showSidebar] = useState(false);

  return (
    <div className='m-1'>
      <CDBSidebar maxWidth='250px' breakpoint={1100} toggled={showSidebar}  className='rounded-3' textColor="#fff" backgroundColor="#198754" >
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>F. Ciências</CDBSidebarHeader>
        <CDBSidebarContent >
          <CDBSidebarMenu >
          {user().data?.roles?.[0].id === 4
              ? adminSidebarItems.map((sidebarItem) => (
                  <SidebarItem key={sidebarItem.id} iname={sidebarItem.iname} text={sidebarItem.text} link={sidebarItem.link} subItems={sidebarItem?.subItems} />
                ))
              : commonSidebarItems.map((sidebarItem) => (
                  <SidebarItem key={sidebarItem.id} iname={sidebarItem.iname} text={sidebarItem.text} link={sidebarItem.link} subItems={sidebarItem?.subItems} />
                ))}
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div className='mb-2'
          >
            SAIE-FC-UEM
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  )
};

export default Sidebar;