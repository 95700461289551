import React from 'react'
import PartnersForm from './PartnersForm';
import { useLocation } from 'react-router-dom';

const PartnerEdit = () => {
    const location = useLocation();
    const partner = location.state?.partner;
  
    return <PartnersForm partner={partner} />;
}

export default PartnerEdit