import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';
import countriesApi from '../../services/Countries'


const PartnersForm = ({partner}) => {

  const auth = useAuthHeader()
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Alerta");
  const [severity, setSeverity] = useState("info");

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [name, setName] = useState(partner?.name || '');
  const [phone, setPhone] = useState(partner?.phone || '');
  const [country, setCountry] = useState(partner?.country || '');
  const [adress, setAdress] = useState(partner?.address || '');
  const [email, setEmail] = useState(partner?.email || '');

  const [filteredCountries, setFilteredCountries] = useState([]);

  useEffect(() => {

    const anywhere = [{value : '', label: 'Nenhum'}];

    countriesApi.get('/states')
    .then((res) => {
      const filtered = res.data.data.map((c) => {
        return {value: c.name, label: c.name};
      })
      setFilteredCountries(anywhere.concat(filtered));
    })
    .catch(error=>{})
  },[])

  const onCountryChange = (event, value) => {
    if (value){
      setCountry(value.label);
    }else
      setCountry(null)
  };

  const handleSave = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.post('partner', {
        name, 
        phone,
        adress,
        email,
        country
      },
      {
        headers: {
            Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("Parceiro Adicionado com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/partners')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }

  const handleEdit = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.put(`partner/${partner.id}`, {
        name, 
        phone,
        adress,
        email,
        country
      },
      {
        headers: {
            Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setSeverity('info')
      setHeading('Editado')
      setMessage("Alterações Executadas com sucesso!");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/partners');
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }


  return (
    loading ? (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress color="success" /> 
      </Container>
    ) : (
    <Form style={{ height: "50vh" }} className='d-flex justify-content-center align-items-center'>
      <MyAlert
        showAlert={showAlert}
        handleCloseAlert={handleCloseAlert}
        message={message}
        heading={heading}
        severity={severity}
      />
      <Card >
        <CardBody className='p-3'>
          <Row>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Nome ou Instituição" 
              variant="standard" 
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Telefone" 
              variant="standard" 
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-3'>
          <Col className='mb-3'>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 200}}>
              <Autocomplete
                options={filteredCountries}
                onChange={onCountryChange}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                  {...params}
                  label="Escolha um País"
                  variant="standard"
                  />
                )}
              />
              </FormControl>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                fullWidth type="email" 
                label="Email" 
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                fullWidth
                label="Endereço" 
                variant="standard" 
                value={adress}
                onChange={(e) => setAdress(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="text-end">
            <Col>
              {!partner?.name ? (
                <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
              ) : (
                <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
    )
  )
}

export default PartnersForm