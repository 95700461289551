import React from 'react'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Delete, Edit, Circle } from '@mui/icons-material';
import Paginating from '../Paginating';
import api from '../../services/Api';
import { useState, useEffect } from 'react';
import Loading from '../Loading';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { getCommonEditDeleteClassName } from '../../utils/commonEditDelete';
import MyDialog from '../../utils/MyDeleteDialog';
import { useNavigate } from 'react-router-dom';

function Row(props) {

  const user = useAuthUser()
  const navigate = useNavigate()

  const { row } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleEditClick = () => {
    navigate(`/students/edit`, { state: { student: row } });
  };

  return (
    <React.Fragment>
      <div>
        <MyDialog row={row} open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} url={'student'} title={'Estudante'} />
      </div>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
          <TableCell >
            <IconButton
              aria-label="expand row"
              size="small"
            >
              {<Circle/>}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.last_name+", "+row.name}
          </TableCell>
          <TableCell>{row.course.degree+" em "+row.course.name}</TableCell>
          <TableCell>{row.number}</TableCell>
          <TableCell>{row.email }</TableCell>
          <TableCell>
            <div className={getCommonEditDeleteClassName(user, row)} >
              <Tooltip title="Editar" arrow>
                <IconButton
                  aria-expanded="false"
                  size="small"
                  onClick={handleEditClick}
                >
                <Edit style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Apagar" arrow>
                <IconButton
                  aria-expanded="false"
                  size="small"
                  onClick={handleDeleteClick}
                >
                <Delete style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

const StudentsTable = ({searchKey}) => {
    const auth = useAuthHeader()

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
  
    const getData = async (page) => {
      
      const res = await api.get(`students_paginate?page=${page}`,
      {
        headers: {
          Authorization: auth()
        }
      },
      {
        withCredentials: true
      })
      setData(res.data.students)
      setTotalPages(res.data.students.last_page)
      setLoading(false)
    }

    const getSearchData = async (searchKey, page) => {
      
      const res = await api.get(`studnets/${searchKey}?page=${page}`,
      {
        headers: {
          Authorization: auth()
        }
      },
      {
        withCredentials: true
      })
      setData(res.data.students)
      setTotalPages(res.data.students.last_page)
      setLoading(false)
    }
  
    useEffect(() => {
      setLoading(true)
      if (searchKey !== '') {
        getSearchData(searchKey, currentPage)
      }else
        getData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, searchKey])
  
  
    return (
      <>
        { loading ? (
          <Loading/>
        ) : (
          <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className='bg-success'>
              <TableRow>
                <TableCell/>
                <TableCell className='text-light'><strong>Nome</strong></TableCell>
                <TableCell className='text-light'><strong>Curso</strong></TableCell>
                <TableCell className='text-light'><strong>Numero de Estudante</strong></TableCell>
                <TableCell className='text-light'><strong>E-mail</strong></TableCell>
                <TableCell align="right" className='text-light'><strong>Acçôes</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data?.data?.map((item, index) => (
                  <Row key={index} row={item} data={[item]} />
                ))
              }
            </TableBody>
          </Table>
          <Paginating currentPage={currentPage} total={totalPages} onPageChange={(page) => setCurrentPage(page)}/>
        </TableContainer>
        )}
    </> 
    );
}

export default StudentsTable