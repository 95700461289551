import React from 'react'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Delete, Edit, Circle, Check, Block, LockReset } from '@mui/icons-material';
import Paginating from '../Paginating';
import api from '../../services/Api';
import { useState, useEffect } from 'react';
import Loading from '../Loading';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { getCommonEditDeleteClassName } from '../../utils/commonEditDelete';
import MyDialog from '../../utils/MyDeleteDialog';
import { useNavigate } from 'react-router-dom';
import MyAlert from '../../utils/MyAlert';
import { handleActivation, handleReset } from '../../utils/handleActivate';

function Row(props) {

  const user = useAuthUser()
  const auth = useAuthHeader()
  const navigate = useNavigate()

  const { row } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openValidationAlert, setOpenValidationAlert] = React.useState(false);
  const [validated, setValidated] = useState(false);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleEditClick = () => {
    navigate(`/users/edit`, { state: { user: row } });
  };

  const handleResetClick = async () => {
    try {
      const resetSuccessful = await handleReset(row, auth);
      if (resetSuccessful) {
        setValidated(true)
        setOpenValidationAlert(true);
      }else{
        setValidated(false)
        setOpenValidationAlert(true);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {

  }, [])
  

  const handleActivationClick = async () => {
    try {
      const validationSuccessful = await handleActivation(row, auth, 'user');
      if (validationSuccessful) {
        setValidated(true)
        setOpenValidationAlert(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }else{
        setValidated(false)
        setOpenValidationAlert(true);
      }
    } catch (error) {
    } finally {
    }
  };
  
    return (
      <React.Fragment>
        {openValidationAlert && (
          <MyAlert
            showAlert={true}
            handleCloseAlert={() => setOpenValidationAlert(false)}
            severity= { validated ? "success" : "error"}
            heading={ validated ? "Sucesso!" : "Erro!"}
            message={ validated ? "Alterado com sucesso!" : "Sem sucesso"}
            autoDismissTime={3000}
          />
        )}
      <div>
        <MyDialog row={row} open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} url={'user'} title={'Usuário'} />
      </div>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
          <TableCell >
            <IconButton
              aria-label="expand row"
              size="small"
            >
              {<Circle/>}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}, {row.last_name}
          </TableCell>
          <TableCell>
            {row.country}, {row.province}
          </TableCell>
          <TableCell>{row.academic_level}</TableCell>
          <TableCell>
            {row.type}
          </TableCell>
          <TableCell>{row.email }</TableCell>
          <TableCell>{row.roles[0]?.name}</TableCell>
          <TableCell>{row.active ? "Activo" : "Inactivo"}</TableCell>
          <TableCell align='right'>
            <div className={getCommonEditDeleteClassName(user, row)}>
              <Tooltip title="Editar" arrow>
                <IconButton
                  aria-expanded="false"
                  size="small"
                  onClick={handleEditClick}
                >
                <Edit style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Repor Senha" arrow>
                <IconButton
                  aria-expanded="false"
                  size="small"
                  onClick={handleResetClick}
                >
                <LockReset style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={row.active ? "Desativar" : "Activar"} arrow>
                <IconButton
                  aria-expanded="false"
                  size="small"
                  onClick={handleActivationClick}
                >
                {!row.active ? (<Check style={{ color: "green" }} />) : (<Block style={{ color: "green" }} />) } 
                </IconButton>
              </Tooltip>
              <Tooltip title="Apagar" arrow>
                <IconButton
                  aria-expanded="false"
                  size="small"
                  onClick={handleDeleteClick}
                >
                <Delete style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

const UsersTable = ({searchKey}) => {
    const auth = useAuthHeader()

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
  
    const getData = async (page) => {
      
      const res = await api.get(`users_paginate?page=${page}`,
      {
        headers: {
          Authorization: auth()
        }
      },
      {
        withCredentials: true
      })
      setData(res.data.users)
      setTotalPages(res.data.users.last_page)
      setLoading(false)
    }

    const getSearchData = async (searchKey, page) => {
      
      const res = await api.get(`users/${searchKey}?page=${page}`,
      {
        headers: {
          Authorization: auth()
        }
      },
      {
        withCredentials: true
      })
      setData(res.data.users)
      setTotalPages(res.data.users.last_page)
      setLoading(false)
    }
  
    useEffect(() => {
      setLoading(true)
      if (searchKey !== '') {
        getSearchData(searchKey, currentPage)
      }else
        getData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, searchKey])
  
  
    return (
      <>
        { loading ? (
          <Loading/>
        ) : (
          <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className='bg-success'>
              <TableRow>
                <TableCell/>
                <TableCell className='text-light'><strong>Nomes</strong></TableCell>
                <TableCell className='text-light'><strong>Naturalidade</strong></TableCell>
                <TableCell className='text-light'><strong>Nível Académico</strong></TableCell>                
                <TableCell className='text-light'><strong>Tipo</strong></TableCell>
                <TableCell className='text-light'><strong>E-mail</strong></TableCell>
                <TableCell className='text-light'><strong>Permissões</strong></TableCell>
                <TableCell className='text-light'><strong>Estado</strong></TableCell>
                <TableCell align="right" className='text-light'><strong>Acções</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data?.data?.map((item, index) => (
                  <Row key={index} row={item} data={[item]} />
                ))
              }
            </TableBody>
          </Table>
          <Paginating currentPage={currentPage} total={totalPages} onPageChange={(page) => setCurrentPage(page)}/>
        </TableContainer>
        )}
    </> 
    );
}

export default UsersTable