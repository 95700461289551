import React from 'react'
import Title from '../components/dashContent/Title'
import { Outlet } from 'react-router-dom';

const Articles = () => {
  return (
    <div className='m-1'>
      <Title text="Obras e Artigos"/>
      <Outlet>

      </Outlet>
    </div>
  )
}

export default Articles