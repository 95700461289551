import React from 'react'
import Title from '../components/dashContent/Title'
import { Row, Col, Card, CardBody } from 'reactstrap'

const Home = () => {
  return (
    <div className='m-1'>
      <Title text="Página Inicial"/>
      <Row>
        <Col>
          <Card className='py-2 mb-3'>
            <CardBody className='text-success'>
              <h1>Sobre</h1>
              <hr />
              <div className='p-3'>
                <p>
                A <strong> Faculdade de Ciências (FC) </strong> realiza e promove a investigação de mérito nacional e internacional. Nesta área, contribui ainda para o desenvolvimento de competências e conhecimento científico.
                </p>
                <p>
                A Faculdade providencia, em todas as suas actividades, a compreensão da importância da ciência nas outras áreas de saber e desenvolve actividades de extensão conjuntas com a comunidade, organizações públicas e privadas, e outros demais interessados. 
                </p>
                <hr />
                <p>
                As actividades de Investigação e extensão na Faculdade de Ciências são coordenadas por um Director-Adjunto, a nível da Direcção, e estão presentes em cada um dos Departamentos Académicos e Centros.
                </p>
                <p>
                Anualmente a Faculdade de Ciências projecta, avalia e aprova trabalhos de investigação inseridos nas actividades dos Departamentos Académicos e do Centro de Investigação.
                </p>

                <p>
                Os diferentes projectos de investigação são concebidos e desenvolvidos por docentes e investigadores nos Departamentos e em suas áreas/linhas, ou mesmo grupos de Investigação. 
                </p>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody className='text-success'>
              <h3>SAIE</h3>
              <hr />
              <div className='p-3'>  
                <p>
                  O Sistema em questão existe para auxiliar na documentação dos eventos e projectos decorridos, assim como na divulgação de conteúdo cíentifico da faculdade e por fim dar relátorios precisos sobre as actividades, projectos, eventos, obras e trabalhos que tenham sido executados!
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Home