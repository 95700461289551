import React from 'react'
import SupervisorsForm from './SupervisorsForm';
import { useLocation } from 'react-router-dom';

const SupervisorsEdit = () => {
    const location = useLocation();
    const supervisor = location.state?.supervisor;
  
    return <SupervisorsForm supervisor={supervisor} />;
}

export default SupervisorsEdit