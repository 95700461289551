import api from '../services/Api';

export const handleActivation = async (row, auth, url) => {

  const validationData = !row.active;
  try {

    await api.put(`${url}/${row.id}`, {active: validationData}, {
      headers: {
        Authorization: auth(),
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const handleReset = async (row, auth) => {
  try {

    await api.put(`reset_password/${row.id}`,{}, {
      headers: {
        Authorization: auth(),
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
