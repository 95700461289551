import React from 'react';
import { useLocation } from 'react-router-dom';
import UnitiesForm from './UnitiesForm';

const UnitiesEdit = () => {
  const location = useLocation();
  const unity = location.state?.unity;

  return <UnitiesForm unity={unity} />;
};

export default UnitiesEdit;
