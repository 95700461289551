import { Autocomplete, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import countriesApi from '../../services/Countries'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';


const UsersForm = ({user}) => {

    const auth = useAuthHeader()
    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Mensagem");
    const [heading, setHeading] = useState("Alerta");
    const [severity, setSeverity] = useState("info");

    const roles = [
        {id : 1, label : "Usuário"},
        {id : 2, label : "Director  de Investigação"},
        {id : 3, label : "Chefe de Secção"},
        {id : 4, label : "Administrador"},
    ];

    const types = [
        { id: 1, label: 'Interno' },
        { id: 2, label: 'Externo' },
    ];

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const [name, setName] = useState(user?.name || '');
    const [lastName, setLastName] = useState(user?.last_name || '');
    const [type, setType] = useState(user?.type || '');
    const [academicLevel, setAcademicLevel] = useState(user?.academic_level || '');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(user?.country || '');
    const [filteredStates, setFilteredStates] = useState([]);
    const [countryState, setCountryState] = useState(user?.province || '');


    const [username, setUsername] = useState(user?.username || '');
    const [email, setEmail] = useState(user?.email || '');
    const [password ] = useState('12345678');
    const [match ] = useState('12345678');
    const [role, setRole] = useState(user?.roles[0]?.id || '');
    const [active, setActive] = useState(user?.active || true);

    const academiclevels = [
        {id : '1', name : 'Mestrado'},
        {id : '2', name : 'Licenciado'},
        {id : '3', name : 'Doutorado'},
        {id : '4', name : 'Bacharelato'},
        {id : '5', name : 'Catedrático'}
    ]

    useEffect(() => {

        const anywhere = [{value : '', label: 'Nenhum'}];
    
        countriesApi.get('/states')
        .then((res) => {
            const filtered = res.data.data.map((c) => {
                return {value: c.name, label: c.name};
            })
            setFilteredCountries(anywhere.concat(filtered));
        })
        .catch(error=> {})
    },[])
    
    useEffect(() => {
            
        const selected = selectedCountry.label;
        countriesApi.post('/states',
            { country : selected }
        )
        .then((res) => {
            const filtered = res.data.data.states.map((s) => {
                return {id: s.name, label: s.name};
            })
            setFilteredStates(filtered);
        })
        .catch(error=>{})

    }, [selectedCountry])

    useEffect(() => {
        console.log(active)
    }, [active])
    

    const handleSave = async (e) => {
        e.preventDefault();
    
        try{
            await api.post('user', {
            name, email, password, password_confirmation : match, type: type , last_name: lastName, country: selectedCountry.value, province: countryState, academic_level : academicLevel, username, role_id : role, active : active
            },
            {
                headers: {
                    Authorization : auth()
                },
                withCredentials: true,
                xsrfHeaderName: 'X-XSRF-TOKEN',
            });
            setSeverity('success')
            setHeading('Salvo!')
            setMessage("Usuário Adicionado com sucesso!");
            setShowAlert(true);
            setTimeout(() => {
                navigate('/users');
            }, 2500);
        }catch(error){
            if(error.response){
                if(error.response.status === 422){
                setMessage("Nome do usuario ou e-mail já existentes, troque e tente novamente");
                setSeverity("info");
                setHeading("Dados Existentes");
                setShowAlert(true)
                }else{
                setMessage("Nao foi possivel conectar ao servidor, tente novamente mais tarde");
                setSeverity("error");
                setHeading("Servidor Inalcaçavel");
                setShowAlert(true)
                }
            }else{
                setLoading(false);
                setMessage("Erro Desconhecido");
                setSeverity("error");
                setHeading("Um erro desconhecido foi encontrado, tente contactar um técnico");
                setShowAlert(true)
            }
        }
    }

    const handleEdit = async (e) => {

        e.preventDefault();

        setLoading(true);

        try {
            await api.put(`user/${user.id}`, {
                name, email, type: type , last_name: lastName, country: selectedCountry.value, province: countryState, academic_level : academicLevel, username, role_id : role, active : active
            },
            {
                headers: {
                    Authorization : auth()
                },
                withCredentials: true,
                xsrfHeaderName: 'X-XSRF-TOKEN',
            })
            setSeverity('info')
            setHeading('Editado')
            setMessage("Alterações Executadas com sucesso!");
            setShowAlert(true);
            setTimeout(() => {
                navigate('/users');
            }, 2500);
        }catch(error){
            setLoading(false);
            if(error.response){
                setHeading("Erro")
                setSeverity("error")
                setMessage(error.message);
                setShowAlert(true);
            }
        }
    }


  return (
    loading ? (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
        </Container>
    ) : (
    <Form  className='d-flex justify-content-center align-items-center'>
        <MyAlert
            showAlert={showAlert}
            handleCloseAlert={handleCloseAlert}
            message={message}
            heading={heading}
            severity={severity}
        />
        <Card >
            <CardBody className='p-3'>
            <Divider className='p-2'>Dados do Usuário</Divider>
                <Row>
                    <Col lg={6}>
                        <TextField 
                        fullWidth
                        label="Nome" 
                        variant="standard" 
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField 
                        required
                        fullWidth
                        label="Apelido" 
                        variant="standard" 
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col lg={6} className='mb-3'>
                        <FormControl fullWidth variant="standard" sx={{ minWidth: 200}}>
                        <Autocomplete
                            options={filteredCountries}
                            onChange={(event, newValue) => {
                                setSelectedCountry(newValue);
                            }}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Escolha um País"
                                    variant="standard"
                                />
                            )}
                        />
                        </FormControl>
                    </Col>
                    <Col lg={6} className="mb-3">
                    <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel>Província</InputLabel>
                        <Select
                            label="Província"
                            onChange={(e) => setCountryState(e.target.value)}
                            value={countryState}
                        >
                            {filteredStates.map((state, index) => (
                                <MenuItem key={index} value={state.label}> 
                                    {state.label} 
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel>Tipo de Usuario</InputLabel>
                        <Select
                            label="Tipo"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            >
                            {
                                types.map((type, index) => (
                                <MenuItem value={type.label} id={index}> 
                                    {type.label} 
                                </MenuItem>
                                ))
                            }
                        </Select>
                        </FormControl>
                    </Col>
                    <Col lg={6}>
                        <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel>Nivel Académico</InputLabel>
                        <Select
                            label="Moeda"
                            onChange={(e) => setAcademicLevel(e.target.value)}
                            value={academicLevel}
                            >
                            {
                                academiclevels.map((level, index) => (
                                <MenuItem value={level.name} id={index}> 
                                    {level.name} 
                                </MenuItem>
                                ))
                            }
                        </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Divider></Divider>
                <Divider className='p-2'>Credênciais</Divider>
                <Divider></Divider>
                <Row className='mb-3'>
                    <Col lg={6}>
                        <TextField 
                            fullWidth                            
                            label="Username" 
                            variant="standard"
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Col>
                    <Col lg={6}>
                        <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel>Role/Permissão</InputLabel>
                            <Select
                                label="Role"
                                onChange={(e) => setRole(e.target.value)}
                                value={role}
                                >
                                {
                                    roles.map((role, index) => (
                                    <MenuItem value={role.id} id={index}> 
                                        {role.label} 
                                    </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row >
                    <Col className='pb-3'>
                        <TextField 
                            fullWidth 
                            type="email" 
                            label="Email" 
                            variant="standard"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Col>
                    <Col lg={6}>
                        <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel>Estado</InputLabel>
                        <Select
                            label="Estado"
                            onChange={(e) => setActive(e.target.value)}
                            value={active}
                            >
                                <MenuItem value={true}> 
                                    Activo 
                                </MenuItem>
                                <MenuItem value={false}> 
                                    Inactivo 
                                </MenuItem>
                        </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row className="text-end">
                    <Col>
                    {!user?.name ? (
                        <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
                    ) : (
                        <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
                    )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Form>
    )
  )
}

export default UsersForm