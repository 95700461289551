import React, { useState } from 'react'
import Search from '../Search';
import CollapsibleTable from './CollapsibleTable';
import FloatingAddButton from '../../utils/FloatingAddButton';

const List = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title={"Pesquise por Título, Palavras Chave ou Citação do Artigo"} onSearch={handleSearch}/>
      <CollapsibleTable searchKey={searchKey}/> 
      <FloatingAddButton toolTitle="Adicionar novo Artigo" link='new'/>
    </>
  )
}

export default List