import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import PartnersTable from './PartnersTable';
import { useState } from 'react';

const PartnersList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title ={ "Pesquise o nome de um Parceiro"} onSearch={handleSearch}/>
      <PartnersTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle={"Adicionar novo Parceiro"} link={"new"}/>
    </>
  )
}

export default PartnersList