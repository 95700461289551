import React, { useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import SummarizeIcon from '@mui/icons-material/Summarize';
import StepWizard from "react-step-wizard";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Row, Col, Button, CardBody, Card, Container } from "reactstrap";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import api from '../../services/Api';
import { useAuthHeader } from 'react-auth-kit';
import { Check, Handshake} from "@mui/icons-material";
import MyAlert from "../../utils/MyAlert";
import { useNavigate } from 'react-router-dom';

const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div>
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <IconButton color="success" onClick={handleBack}>
              <ArrowBackIosNewIcon/>
            </IconButton>
          </Col>
        )}
        <Col className="text-end">
          {props.currentStep < props.totalSteps && (
            <IconButton color="success" onClick={handleNext}>
              <ArrowForwardIosIcon/>
            </IconButton>
          )}
          {props.currentStep === props.totalSteps && (
            <Button className="bg-success" onClick={handleFinish}>Guardar</Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const One = (props) => {

  const { work } = props;
  const [title, setTitle] = useState( work?.title || {});
  const [area, setArea] = useState(work?.area || {});
  const [datePresented, setDatePresented] = useState( work?.date_presented || {});
  const [link, setLink] = useState( work?.link || {});
  const [student, setStudent] = useState( work?.student_id || {});

  const [error, setError] = useState("");

  const onTitleChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setTitle((title) => ({
      ...title,
      [targetName]: targetValue
    }));
  };

  const onAreaChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setArea((area) => ({
      ...area,
      [targetName]: targetValue
    }));
  };

  const onDateChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setDatePresented((datePresented) => ({
      ...datePresented,
      [targetName]: targetValue
    }));
  };

  const onLinkChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setLink((link) => ({
      ...link,
      [targetName]: targetValue
    }));
  };

  const onStudentChange = (event, value) => {
    const selectedStudent = value;

    setStudent((prevStudent) => ({
      ...prevStudent,
      student: selectedStudent
    }));
  };


  const validate = () => {
    if (!title.title || !datePresented.datePresented || !student.student || !link.link)
      setError("Titulo, data, link e selecção do estudante obrigatório");
    else {
      setError("");
      props.nextStep();
      props.userCallback(title);
      props.userCallback(area);
      props.userCallback(datePresented);
      props.userCallback(link);
      props.userCallback(student);
    }
  };


  return (
    <Card>
      <CardBody className='p-3'>
        <Row>
          <span style={{ color: "red" }}>{error}</span>
          <Col>
            <TextField 
              fullWidth
              label="Tema do trabalho" 
              variant="standard" 
              name="title"
              onChange={onTitleChange}
            />
          </Col>
        </Row>
        <Row className='my-3'>
          <Col>
            <TextField 
              fullWidth
              label="Área do trabalho" 
              variant="standard" 
              name="area"
              onChange={onAreaChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputLabel>Data de Apresentação</InputLabel>
            <TextField 
              fullWidth 
              type={'date'}
              variant="standard"
              name="datePresented"
              onChange={onDateChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputLabel>Link do Trabalho</InputLabel>
            <TextField 
              fullWidth 
              variant="standard"
              name="link"
              onChange={onLinkChange}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col  className="mt-3">
            <FormControl fullWidth variant="standard">
              <Autocomplete
                options={props.students}
                onChange={onStudentChange}
                getOptionLabel={(option) => "Nr. "+option.number+", "+option.name+" "+option.last_name}
                renderInput={(params)=> (
                  <TextField
                    {...params}
                    label="Selecione o Autor"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Col>
        </Row>
        <Row>
          <ActionButtons {...props} nextStep={validate} />
        </Row>
      </CardBody>
    </Card>
  );
};


const Two = (props) => {

  const [error, setError] = useState("");

  const [supervisorInputs, setSupervisorInputs] = useState([
    { supervisor: null, involvement: "" },
  ]);

  const handleSupervisorChange = (event, newValue, index) => {
    const updatedSupervisorInputs = [...supervisorInputs];
    updatedSupervisorInputs[index].supervisor = newValue;
    setSupervisorInputs(updatedSupervisorInputs);
  };

  const handleInvolvementChange = (event, index) => {
    const value = event.target.value;
    const updatedSupervisorInputs = [...supervisorInputs];
    updatedSupervisorInputs[index].involvement = value;
    setSupervisorInputs(updatedSupervisorInputs);
  };

  const handleAddSupervisor = () => {
    setSupervisorInputs([...supervisorInputs, { supervisor: null, involvement: "" }]);
  };

  const validate2 = () => {
    if (!supervisorInputs) 
      setError("Todos os campos são obrigatórios");
    else {
      setError("");
      props.nextStep();
      props.userCallback({supervisors: supervisorInputs});
    }
  };

  return (
    <div>
      <span style={{ color: "red" }}>{error}</span>
      <Card>
        <CardBody className='p-3'>
        {supervisorInputs.map((input, index) => (
          <div key={index}>
          <Row className='mb-3'>
            <Col>
              <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
              <Autocomplete
                value={input.supervisor}
                onChange={(event, newValue) => handleSupervisorChange(event, newValue, index)}
                options={props.supervisors}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Supervisor" variant="standard" />
                )}
              />
              </FormControl>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                label="Papel" 
                variant="standard" 
                name="involvement"
                fullWidth
                value={input.involvement}
                onChange={(event) => handleInvolvementChange(event, index)}
              />
            </Col>
          </Row>
          </div>
          ))}
          <Row className='mb-3'>
            <Col>
              <div>
                <Button onClick={handleAddSupervisor}>Adicionar Novo Supervisor</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ActionButtons {...props} nextStep={validate2} />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const Three = (props) => {

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <Container>
      <Card>
        <CardBody className="p-3 m-3">
          <h3>Confirme os dados</h3>
          <br />
          <Row>
            <h4>Dados do Trabalho</h4>
            <Row>
              <Col>
                Titulo : <strong>{props.user.title}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Area : <strong>{props.user.area}</strong>
              </Col>
              <Col>
                Data De Apresentação : <strong>{props.user.datePresented}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Estudante Autor : <strong>{props.user?.student?.name}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Link para o Trabalho : <strong>{props.user?.link}</strong>
              </Col>
            </Row>
            <br />
            <h4>Supervisor(es)</h4>
            {
              props.user?.supervisors?.map((supervisor) => (
                <Row>
                  <Col>
                    Supervisor : <strong>{supervisor.supervisor.name}</strong>
                  </Col>
                  <Col>
                    Envolvimento : <strong>{supervisor.involvement}</strong>
                  </Col>
                </Row>
              ))
            }
          </Row>
        </CardBody>
      </Card>
    <br />
    <ActionButtons {...props} lastStep={handleLastStep} />
    </Container>
  );
}

const WorkStepper = ( {work} ) => {

  const auth = useAuthHeader()

  const [students, setStudents] = useState([]);
  const [supervisors, setSupervisors] = useState([]);

  const getStudents = async () => {
    
    const res = await api.get(`students`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setStudents(res.data.students)
  }
  

  const getSupervisors = async () => {
    
    const res = await api.get(`supervisors`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setSupervisors(res.data.supervisors)
  }

  useEffect(() => {
    getStudents();
    getSupervisors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  const [, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignUser = (val) => {
    setUser((user) => ({
      ...user,
      ...val
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleComplete = async () => {
    setLoading(true)
    try {
      await api.post('work', {
        title: user.title,
        area: user.area,
        date_presented: user.datePresented,
        link: user.link,
        student_id: parseInt(user.student.id),
        supervisors :
          user?.supervisors?.map((supervisor) => (
            {
              id: parseInt(supervisor.supervisor.id),
              involvement: supervisor.involvement
            }
          )),
      },
      {
        headers: {
          Authorization: auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      navigate('/works')
      setLoading(false)
      setMessage("Trabalho Adicionado com Sucesso");
      setShowAlert(true);
    }catch(error){
      setLoading(false);
      if(error.response){
        setMessage("Erro ao tentar Salvar");
        setShowAlert(true);
      }
    }
  };

  return (
    <>
      {
        loading ? (
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
          </Container>
        ) : (
          <Container>
            <MyAlert
              showAlert={showAlert}
              handleCloseAlert={handleCloseAlert}
              message={message}
            />
            <Stepper activeStep={activeStep}>
              <Step label="Dados Trabalho" children={<SummarizeIcon />} className={activeStep === 0 ? "bg-success" : ""} />
              <Step label="Supervisores" children={<Handshake />} className={activeStep === 1 ? "bg-success" : ""}/>
              <Step label="Confirmação" children={<Check/>} className={activeStep === 2 ? "bg-success" : ""} />
            </Stepper>
            <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
              <One userCallback={assignUser} students={students} work={work}/>
              <Two user={user} userCallback={assignUser} supervisors={supervisors} work={work}/>
              <Three user={user} completeCallback={handleComplete}/>
            </StepWizard>
          </Container>
        )
      }
    </>
  );
};

export default WorkStepper;
