import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useAuthHeader } from 'react-auth-kit';
import api from '../services/Api';
import MyAlert from './MyAlert';
import { useState } from 'react';

const MyDialog = ({ row, open, onClose, url, title }) => {
  const auth = useAuthHeader();
  const [showAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Cabeçalho");
  const [severity, setSeverity] = useState("info");
  

  const handleDelete = async () => {
    onClose();
    try {
      await api.delete(`${url}/${row.id}`, {
        headers: {
          Authorization: auth(),
        },
      }); 
      setMessage(`${title} Eliminado com sucesso`);
      setSeverity('success');
      setHeading('Eliminado!')
      setShowAlert(true);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      setMessage(error.message);
      setSeverity('error');
      setHeading('Erro na requisição!')
      setShowAlert(true);
    }
  };

  
  return (
    <div>
      <MyAlert 
        showAlert={showAlert}
        message={message}
        heading={heading}
        severity={severity}
        handleCloseAlert={handleCloseAlert}
      />
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar {title}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta acção é irreversível!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Não</Button>
          <Button onClick={handleDelete} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyDialog;
