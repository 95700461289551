import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import countriesApi from '../../services/Countries'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';


const AutorsForm = ({autor}) => {

    const auth = useAuthHeader()
    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Mensagem");
    const [heading, setHeading] = useState("Alerta");
    const [severity, setSeverity] = useState("info");

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const [name, setName] = useState(autor?.name || '');
    const [lastName, setLastName] = useState(autor?.last_name || '');
    const [country, setCountry] = useState(autor?.country || '');
    const [academicLevel, setAcademicLevel] = useState(autor?.academic_level || '');
    const [email, setEmail] = useState(autor?.email || '');
    const [orcid, setOrcid] = useState(autor?.orcid || '');
 
    const [filteredCountries, setFilteredCountries] = useState([]);

    const academiclevels = [
        {id : '1', name : 'Mestrado'},
        {id : '2', name : 'Licenciado'},
        {id : '3', name : 'Doutorado'},
        {id : '4', name : 'Bacharelato'},
        {id : '5', name : 'Catedrático'}
    ]

    useEffect(() => {

        const anywhere = [{value : '', label: 'Nenhum'}];
    
        countriesApi.get('/states')
        .then((res) => {
            const filtered = res.data.data.map((c) => {
                return {value: c.name, label: c.name};
            })
            setFilteredCountries(anywhere.concat(filtered));
        })
        .catch(error => {})
    },[])

    const onCountryChange = (event, value) => {
        if (value){
            setCountry(value.label);
        }else
            setCountry(null)
    };

    const handleSave = async (e) => {

        e.preventDefault();

        setLoading(true);

        try {
            await api.post('autor', {
                name, 
                last_name : lastName,
                country,
                academic_level : academicLevel,
                email,
                orcid
            },
            {
                headers: {
                    Authorization : auth()
                },
                withCredentials: true,
                xsrfHeaderName: 'X-XSRF-TOKEN',
            })
            navigate('/autors')
            setLoading(false)
            setMessage("Autor Adicionado com Sucesso");
            setShowAlert(true);
        }catch(error){
            setLoading(false);
            if(error.response){
                setMessage("Erro ao tentar Salvar");
                setShowAlert(true);
            }
        }
    }

    const handleEdit = async (e) => {

        e.preventDefault();

        setLoading(true);

        try {
            await api.put(`autor/${autor.id}`, {
                name, 
                last_name : lastName,
                country,
                academic_level : academicLevel,
                email,
                orcid
            },
            {
                headers: {
                    Authorization : auth()
                },
                withCredentials: true,
                xsrfHeaderName: 'X-XSRF-TOKEN',
            })
            setSeverity('info')
            setHeading('Editado')
            setMessage("Alterações Executadas com sucesso!");
            setShowAlert(true);
            setTimeout(() => {
                navigate('/autors');
            }, 2500);
        }catch(error){
            setLoading(false);
            if(error.response){
                setHeading("Erro")
                setSeverity("error")
                setMessage(error.message);
                setShowAlert(true);
            }
        }
    }


  return (
    loading ? (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
        </Container>
    ) : (
    <Form style={{ height: "50vh" }} className='d-flex justify-content-center align-items-center'>
        <MyAlert
            showAlert={showAlert}
            handleCloseAlert={handleCloseAlert}
            message={message}
            heading={heading}
            severity={severity}
        />
        <Card >
            <CardBody className='p-3'>
                <Row>
                    <Col md={6}>
                        <TextField 
                        fullWidth
                        label="Nome" 
                        variant="standard" 
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Col>
                    <Col md={6}>
                        <TextField 
                        required
                        fullWidth
                        label="Apelido" 
                        variant="standard" 
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col className='mb-3'>
                        <FormControl fullWidth variant="standard" sx={{ minWidth: 200}}>
                        <Autocomplete
                            options={filteredCountries}
                            onChange={onCountryChange}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Escolha um País"
                                variant="standard"
                                />
                            )}
                        />
                        </FormControl>
                    </Col>
                    <Col>
                    <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel>Nivel Académico</InputLabel>
                        <Select
                            label="Moeda"
                            onChange={(e) => setAcademicLevel(e.target.value)}
                            value={academicLevel}
                            >
                            {
                                academiclevels.map((level, index) => (
                                <MenuItem value={level.name} id={index}> 
                                    {level.name} 
                                </MenuItem>
                                ))
                            }
                        </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={6} >
                        <TextField 
                            fullWidth type="email" 
                            label="Email" 
                            variant="standard"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Col>
                    <Col sm={6}>
                        <TextField 
                            fullWidth                            
                            label="ORCID" 
                            variant="standard"
                            required
                            value={orcid}
                            onChange={(e) => setOrcid(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="text-end">
                    <Col>
                    {!autor?.name ? (
                        <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
                    ) : (
                        <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
                    )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Form>
    )
  )
}

export default AutorsForm