import api from '../services/Api';

export const handleValidation = async (row, user, auth, url) => {
  function data() {
    if (user().data?.roles[0].id === 2) {
      return { validation: true };
    } else if (user().data?.roles[0].id === 3) {
      return { validation2: true };
    } else if (user().data?.roles[0].id === 4) {
      return { validation: true, validation2: true };
    }else{
      return
    }
  }

  try {
    const validationData = data();

    await api.put(`${url}/${row.id}`, validationData, {
      headers: {
        Authorization: auth(),
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
