import React from 'react'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Delete, Edit, Circle } from '@mui/icons-material';
import Paginating from '../Paginating';
import api from '../../services/Api';
import { useState, useEffect } from 'react';
import Loading from '../Loading';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { getCommonEditDeleteClassName } from '../../utils/commonEditDelete';
import MyDialog from '../../utils/MyDeleteDialog';
import { useNavigate } from 'react-router-dom';

function Row(props) {

  const user = useAuthUser()
  const navigate = useNavigate()

  const { row } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleEditClick = () => {
    navigate(`/partners/edit`, { state: { partner: row } });
  };

  return (
    <React.Fragment>
      <div>
        <MyDialog row={row} open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} url={'partner'} title={'Parceiro'} />
      </div>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
        <TableCell >
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {<Circle/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.adress}
        </TableCell>
        <TableCell>
          {row.country}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.phone }</TableCell>
        <TableCell align='right'>
          <div className={getCommonEditDeleteClassName(user, row)} >
            <Tooltip title="Editar" arrow>
              <IconButton
                aria-expanded="false"
                size="small"
                onClick={handleEditClick}
              >
              <Edit style={{ color: "green" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Apagar" arrow>
              <IconButton
                aria-expanded="false"
                size="small"
                onClick={handleDeleteClick}
              >
              <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const PartnersTable = ({searchKey}) => {
    const auth = useAuthHeader()

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
  
    const getData = async (page) => {
      
      const res = await api.get(`partners_paginate?page=${page}`,
      {
        headers: {
          Authorization: auth()
        }
      },
      {
        withCredentials: true
      })
      setData(res.data.partners)
      setTotalPages(res.data.partners.last_page)
      setLoading(false)
    }

  const getSearchData = async (searchKey, page) => {

    const res = await api.get(`partners/${searchKey}?page=${page}`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setData(res.data.partners)
    setTotalPages(res.data.partners.last_page)
    setLoading(false)
  }
  
    useEffect(() => {
      setLoading(true)
      if (searchKey !== '') {
        getSearchData(searchKey, currentPage)
      }else
        getData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, searchKey])
  
  
    return (
      <>
        { loading ? (
          <Loading/>
        ) : (
          <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className='bg-success'>
              <TableRow>
                <TableCell/>
                <TableCell className='text-light'><strong>Nome</strong></TableCell>
                <TableCell className='text-light'><strong>Endereço </strong></TableCell>
                <TableCell className='text-light'><strong>País</strong></TableCell>
                <TableCell className='text-light'><strong>E-mail</strong></TableCell>
                <TableCell className='text-light'><strong>Telefone</strong></TableCell>
                <TableCell align="right" className='text-light'><strong>Acções</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data?.data?.map((item, index) => (
                  <Row key={index} row={item} data={[item]} />
                ))
              }
            </TableBody>
          </Table>
          <Paginating currentPage={currentPage} total={totalPages} onPageChange={(page) => setCurrentPage(page)}/>
        </TableContainer>
        )}
    </> 
    );
}

export default PartnersTable