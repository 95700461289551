import React, {useState} from 'react'
import {
  CDBSidebarMenuItem,
} from 'cdbreact';
import './sidebaritem.css'
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import SidebarSubItem from './sidebarSubItem';
import List from '@mui/material/List';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Col, Row } from 'reactstrap';

const SidebarItem = ({text, iname, link, subItems }) => {

  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (subItems && subItems.length > 0) {
      setOpen(!open);
    }
  };  

  return (
    <div 
      className={` ${isHovered ? 'highlighted' : ''} ${isHovered ? 'active' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleOpen}
      > 

      <Link to={link}>
        <CDBSidebarMenuItem iconClassName={ isHovered && 'text-success'} icon={iname} iconSize='sm'>
          <Row className={`align-items-center justify-content-between`}>
            <Col  xs="auto" className={isHovered ? 'text-success' : ''}>
              {text}
            </Col>
            <Col xs="auto" className={isHovered ? 'text-success' : ''}>
              {subItems && subItems.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
            </Col>
          </Row>
        </CDBSidebarMenuItem>
      </Link>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {subItems?.map((subItem) => (
            <SidebarSubItem
              key={subItem.id}
              iname={subItem.iname}
              text={subItem.text}
              link={subItem.link}
              handleOpen={handleOpen}
            />
          ))}
        </List>
      </Collapse>
    </div>
  )
}

export default SidebarItem