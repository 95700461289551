import { Pagination } from '@mui/material'
import React from 'react'

const Paginating = ({ currentPage, total, onPageChange }) => {

  return (
    <div className='d-flex justify-content-end'> 
        <Pagination className='py-2' 
          page={currentPage}
          count={ total }
          showFirstButton 
          showLastButton
          onChange={(event, page) => onPageChange(page)}
        />
    </div>
  )
}

export default Paginating