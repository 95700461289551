import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
const LOGIN_URL = "/login";

const api = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json'
    }
});

const interceptResponse = (response) => {
    if (response.status === 401) {
        window.location.href = LOGIN_URL;
    }
    return response;
};

const interceptError = (error) => {
    if (error.response && error.response.status === 401) {
        window.location.href = LOGIN_URL;
    }
    return Promise.reject(error);
};

api.interceptors.response.use(
    (response) => interceptResponse(response),
    (error) => interceptError(error)
);

export default api;