import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Container, Form, Row } from 'reactstrap';
import { CircularProgress, Divider, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Stack} from '@mui/material';
import { useState } from 'react';
import { Email, Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import api from '../services/Api';
import MyAlert from '../utils/MyAlert';
import { useLocation, Navigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ChangePassword({ handleOpenDialog, initialOpen }) {

    const auth = useAuthHeader()
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("As senhas devem ser iguais e com mais de 8 caracteres");
    const [heading, setHeading] = useState("ATENÇÂO!");
    const [severity, setSeverity] = useState("info");

    const location = useLocation();
    const currentRoute = location.pathname;

    const [open, setOpen] = React.useState(initialOpen);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleOpen = () => {
        setOpen(true);
        handleOpenDialog(true); 
    };

    const handleClose = () => {
        setOpen(false);
        handleOpenDialog(false);
    };

  const [showPassword, setShowPassword] = React.useState(false);
  const [err, setErr] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };  

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [match, setMatch] = useState('');

  useEffect(() => {
    newPassword === match ? setErr(true) : setErr(false)
  }, [newPassword ,match])

  const handleSave = async (e) => {

    e.preventDefault();

    if (newPassword !== match || newPassword.length < 8 ) {
        setShowAlert(true);
        return
    }

    setLoading(true);

    try {
        await api.put(`user_password`, {
            email, password, new_password: newPassword, new_password_confirmation: match
        },
        {
            headers: {
                Authorization : auth()
            },
            withCredentials: true,
            xsrfHeaderName: 'X-XSRF-TOKEN',
        })
        setLoading(false);
        setSeverity('info')
        setHeading('Editado')
        setMessage("Alterações Executadas com sucesso!");
        setShowAlert(true);
        if (currentRoute !== '/login'){
            setTimeout(() => {
                Navigate('login')
            },1200)
        }else
            window.location.reload();
    }catch(error){
        setLoading(false);
        if(error.response){
            setHeading("Erro")
            setSeverity("error")
            setMessage(error.message);
            setShowAlert(true);
        }
    }
}

  return (
    loading ? (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
        </Container>
    ) : (
    <Form>
        <MyAlert
            showAlert={showAlert}
            handleCloseAlert={handleCloseAlert}
            message={message}
            heading={heading}
            severity={severity}
        />
      <Button onClick={handleOpen}>Trocar Senha</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className='p-3 mx-4 text-center' id="modal-modal-title" variant="h6" component="h2">
            Trocar Senha
          </Typography>
            <Row>
                <FormControl variant="standard">
                    <InputLabel>E-mail</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            >
                            <Email />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            </Row>
            <Row>
                <FormControl variant="standard">
                    <InputLabel>Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            </Row>
            <div className='p-2'>
                <Divider/>
            </div>
            <Row>
                <FormControl variant="standard">
                    <InputLabel>Nova Senha</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            >
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
                <FormHelperText>A senha deve ter no mínimo 8 caracteres!.</FormHelperText>
            </Row>
            <Row>
                <FormControl variant="standard">
                    <InputLabel>Confirme Nova senha</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={'password'}
                        value={match}
                        onChange={(e) => setMatch(e.target.value)}
                        
                    />
                    {err ? <FormHelperText>Insira a nova senha novamente para confirmação.</FormHelperText> : <FormHelperText>Insira a nova senha novamente para confirmação, as senhas devem ser iguais!</FormHelperText>}
                </FormControl>
            </Row>
            <Row>
                <div className='p-2 mx-3'>
                <Stack spacing={7} direction="row" className='text-center m-1 p-3'>
                    <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                    <Button variant="outlined" onClick={handleSave}>Alterar</Button>
                </Stack>
                </div>
            </Row>
        </Box>
      </Modal>
    </Form>
    )
  );
}
