import React from 'react';
import { useLocation } from 'react-router-dom';
import AutorsForm from './AutorsForm';

const AutorEdit = () => {
  const location = useLocation();
  const autor = location.state?.autor;

  return <AutorsForm autor={autor} />;
};

export default AutorEdit;
