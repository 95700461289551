import React from 'react'
import Search from '../Search'
import CollapsibleTable from './CollapsibleTable'
import FloatingAddButton from '../../utils/FloatingAddButton'
import { useState } from 'react'

const WorksList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title= {"Pesquise por Autor, tipo ou título do Trabalho"} onSearch={handleSearch}/>
      <CollapsibleTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle="Adicionar novo Trabalho" link='new'/>
    </>
  )
}

export default WorksList