import React from 'react'

const Title = ({ text }) => {
  return (
    <div className='rounded-3 p-2 mb-2 bg-success text-light text-center'>
      <h3>{text}</h3>
    </div>
  )
}

export default Title