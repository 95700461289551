import React from 'react'
import { useLocation } from 'react-router-dom';
import FinanciersForm from './FinanciersForm';

const FinancierEdit = () => {
    const location = useLocation();
    const financier = location.state?.financier;
  
    return <FinanciersForm financier={financier} />;
}

export default FinancierEdit