export const getCommonEditDeleteClassName = (userData, row) => {
  const roleId = userData()?.data?.roles[0].id;
  const userId = userData()?.data?.id;
  if ((roleId === 4) ||
      (userId === row.user_id)) {
    return '';
  } else {
    return 'd-none';
  }
};
    