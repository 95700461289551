import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

const RequireAuth = () => {

    const auth = useIsAuthenticated();
    const location = useLocation();

    return (
        auth() ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace /> 
    )
}
 
export default RequireAuth;