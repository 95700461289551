import React from 'react'
import StudentsForm from './StudentsForm';
import { useLocation } from 'react-router-dom';

const StudentEdit = () => {
    const location = useLocation();
    const student = location.state?.student;
  
    return <StudentsForm student={student} />;
}

export default StudentEdit