import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import AutorsTable from './AutorsTable'
import { useState } from 'react';

const AutorsList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
       <Search title={"Pesquise por nome ou apelido do Autor"} onSearch={handleSearch}/>
       <AutorsTable searchKey={searchKey}/>
       <FloatingAddButton toolTitle={"Adicionar novo autor"} link={"new"}/>
    </>
  )
}

export default AutorsList