import React, { useEffect, useState } from "react";
import { CDBBtn } from "cdbreact";
import Select from 'react-select'; 
import countriesApi from '../services/Countries';
import MyAlert from "../utils/MyAlert";
import api from "../services/Api";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { CardBody, CardHeader, CardTitle, FormGroup, Input, Label, Card, Col, Container, Form } from "reactstrap";
import { useSignIn } from 'react-auth-kit';

const REGISTER_URL = 'register';

export const RegisterStepper = () => {

  const signIn = useSignIn();
  const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";

  const [username, setUsername] = useState([]);
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState('');
  const [match, setMatch] = useState('');
  const [role, setRole] = useState('');

  const [name, setName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [type, setType] = useState([]);
  const [academicLevel, setAcademicLevel] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [countryState, setCountryState] = useState([]);

  const [validMatch, setValidMatch] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Cabeçalho");
  const [variant, setVariant] = useState("primary");


  const roles = [
    {value : 1, label : "Pesquisador"},
    {value : 2, label : "Dir. Investigação"},
    {value : 3, label : "Chefe da Comissão científica"},
    {value : 4, label : "Administrador"},
  ];

  const types = [
    { value: 'Interno', label: 'Interno' },
    { value: 'Externo', label: 'Externo' },
  ];

  const academicLevels = [
      { value: 'Mestrado', label: 'Mestrado' },
      { value: 'Licenciado', label: 'Licenciado' },
      { value: 'Doutorado', label: 'Doutorado' },
      { value: 'Bacharelato', label: 'Bacharelato' },
      { value: 'Catedratico', label: 'Catedrático' },
  ];

  useEffect(() => {

    const anywhere = [{value : '', label: 'Nenhum'}];

    countriesApi.get('/states')
    .then((res) => {
        const filtered = res.data.data.map((c) => {
            return {value: c.name, label: c.name};
        })
        setFilteredCountries(anywhere.concat(filtered));
    })
    .catch(error=> {})
  },[])

  useEffect(() => {
      
    const selected = selectedCountry.value;
    countriesApi.post('/states',
        { country : selected }
    )
    .then((res) => {
        const filtered = res.data.data.states.map((s) => {
            return {value: s.name, label: s.name};
        })
        setFilteredStates(filtered);
    })
    .catch(error=>{})

  }, [selectedCountry])

  useEffect(() => {
  
    setValidMatch(password === match);
  },[password, match])

  const handleNext = () => {
    setStep(step +1)
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validMatch){
      setMessage("Verifique as senhas e tente novamente, as senhas devem ser iguais!");
      setVariant("danger");
      setHeading("Senhas incompatíveis");
      setShowAlert(true)
      return
    }

    try{
      const response = await api.post(REGISTER_URL, {
        name, email, password, password_confirmation : match, type: type.value , last_name: lastName, country: selectedCountry.value, province: countryState.value, academic_level : academicLevel.value, username, role_id : role.value
      });
      response.data.token && signIn({token : response.data.token, authState : {email : response.data.user}, tokenType : 'Bearer', expiresIn: 43800 })
      navigate(from, {replace: true});
    }catch(error){
      if(error.response){
        if(error.response.status === 422){
          setMessage("Nome do usuario ou e-mail já existentes, troque e tente novamente");
          setVariant("info");
          setHeading("Dados Existentes");
          setShowAlert(true)
        }else{
          setMessage("Nao foi possivel conectar ao servidor, tente novamente mais tarde");
        setVariant("warning");
        setHeading("Servidor Inalcaçavel");
        setShowAlert(true)
        }
      }else{
        setMessage("Erro Desconhecido");
        setVariant("danger");
        setHeading("Um erro desconhecido foi encontrado, tente contactar um técnico");
        setShowAlert(true)
      }
    }
  }

  return (
    <Container style={{ height: "100vh" }} className='d-flex justify-content-center align-items-center'>
      <Card className=" border-0 bg-light">
        <MyAlert
          showAlert={showAlert}
          handleCloseAlert={handleCloseAlert}
          variant={variant}
          heading={heading}
          message={message}
        />
        <CardBody>
          <Form>
            {
            step === 1 && (
              <Container>
                <Card className="border-0 bg-light">
                <div className='bg-success p-3'>
                  <CardHeader className='border-0 text-center text-light'>Dados do Usuario</CardHeader>
                </div>
                <CardTitle className='' ></CardTitle>
                <CardBody>
                  <div className="row">
                    <FormGroup className="mb-3 col-lg-6">
                      <Label> 
                        Nome
                        </Label>
                        <Input 
                        type="text" 
                        placeholder="Insira nome" 
                        autoComplete="off"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                        className="rounded-3 border-0.1"
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-lg-6">
                        <Label> 
                        Apelido
                        </Label>
                        <Input 
                        type="text" 
                        placeholder="Insira Apelido" 
                        autoComplete="off"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        required
                        className="rounded-3 border-0.1"
                        />
                    </FormGroup>
                        <Col lg className="mb-3 col-lg-6">
                        <div className='mb-2'>Pais</div>
                            <Select
                                defaultValue={selectedCountry}
                                onChange={setSelectedCountry}
                                options={filteredCountries}
                                placeholder={'Pais'}
                                isSearchable={true}
                            />
                        </Col>
                        <Col lg className="mb-3 col-lg-6">
                        <div className='mb-2'>Provincia</div>
                            <Select
                                defaultValue={countryState}
                                onChange={setCountryState}
                                options={filteredStates}     
                                placeholder={'Provincia'}
                                isSearchable={true}
                            />
                        </Col>
                        <Col lg className="mb-3 col-lg-6">
                        <div className='mb-2'>Tipo de Usuario</div>
                            <Select
                                defaultValue={type}
                                onChange={setType}
                                options={types}
                                placeholder={'Tipo de Usuario'}
                            />
                        </Col>
                        <Col lg className="mb-3 col-lg-6">
                        <div className='mb-2'>Nível Académico</div>
                            <Select
                                defaultValue={academicLevel}
                                onChange={setAcademicLevel}
                                options={academicLevels}     
                                placeholder={'Nivel Academico'}
                                isSearchable={true}
                            />
                        </Col>
                    </div>
                </CardBody>
                </Card>
                <div className='text-center pb-3 d-flex justify-content-center'>
                <CDBBtn outline color="success" onClick={handleNext}>
                  Proximo
                </CDBBtn>
                </div>
                <p className="text-center" >Já tem credenciais? <Link className="link-primary mx-2" to="/login">Login</Link></p>
              </Container>
            )
            }{
            step === 2 &&(
              <Container>
                <Card className="border-0 bg-light">
                    <div className='bg-success p-3'>
                        <CardHeader className='border-0 text-center text-light'>Dados de Acesso e Permissões</CardHeader>
                    </div>
                    <CardTitle className='pt-3' ></CardTitle>
                    <CardBody>
                      <Form className="row">
                            <FormGroup className="mb-3 col-lg-9">
                                <Label> 
                                    Nome de Usuario
                                </Label>
                                <Input 
                                type="text" 
                                placeholder="Insira nome do Usuário" 
                                autoComplete="off"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                required
                                className="rounded-3 border-0.1"
                                />
                            </FormGroup>
                            <Col lg className="mb-3 col-lg-3">
                                <div className='mb-2'>Role</div>
                                <Select
                                    defaultValue={role}
                                    onChange={setRole}
                                    options={roles}     
                                    placeholder={'Papel'}
                                />
                            </Col>
                            <FormGroup className="mb-3">
                                <Label htmlFor="email"> 
                                    Endereço de e-mail
                                </Label>
                                <Input 
                                    type="email" 
                                    placeholder="Insira e-mail" 
                                    autoComplete="off"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                    className="rounded-3 border-0.1"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3 col-lg-6">
                                <Label htmlFor="password"> Senha</Label>
                                <Input 
                                    type="password" 
                                    placeholder="Senha" 
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    className="rounded-3 border-0.1"
                                />
                            </FormGroup>
                            <FormGroup className="mb-3 col-lg-6">
                            <Label> Confirme Senha</Label>
                            <Input 
                                type="password" 
                                placeholder="Confirme a senha" 
                                onChange={(e) => setMatch(e.target.value)}
                                required
                                value={match}
                                className="rounded-3 border-0.1"
                            />
                        </FormGroup>
                      </Form>
                    </CardBody>
                </Card>
                <div className='text-center pb-3 d-flex justify-content-center'>
                <CDBBtn outline className="mx-3" color="success" onClick={handleBack}>
                  Anterior
                </CDBBtn>
                <CDBBtn outline type="submit" className="mx-3" color="success" onClick={handleSubmit}>
                  Registar
                </CDBBtn>
                </div>
              </Container>
            )
          }
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default RegisterStepper;