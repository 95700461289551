import React, { useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import SummarizeIcon from '@mui/icons-material/Summarize';
import StepWizard from "react-step-wizard";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Row, Col, Button, CardBody, Card, Container } from "reactstrap";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../services/Api';
import { useAuthHeader } from 'react-auth-kit';
import { Check, Handshake} from "@mui/icons-material";
import MyAlert from "../../utils/MyAlert";
import { useNavigate } from 'react-router-dom';
import countriesApi from "../../services/Countries";

const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div>
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <IconButton color="success" onClick={handleBack}>
              <ArrowBackIosNewIcon/>
            </IconButton>
          </Col>
        )}
        <Col className="text-end">
          {props.currentStep < props.totalSteps && (
            <IconButton color="success" onClick={handleNext}>
              <ArrowForwardIosIcon/>
            </IconButton>
          )}
          {props.currentStep === props.totalSteps && (
            <Button className="bg-success" onClick={handleFinish}>Guardar</Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const One = (props) => {
  const [name, setName] = useState({});
  const [type, setType] = useState({});
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [targetAudience, setTargetAudience] = useState({});
  const [country, setCountry] = useState({});
  const [location, setLocation] = useState({});
  const [details, setDetails] = useState({});

  const [filteredCountries, setFilteredCountries] = useState([]);
  const [error, setError] = useState("");

  const [unityInputs, setUnityInputs] = useState([
    { unity: null, involvement: "" },
  ]);

  const handleUnityChange = (event, newValue, index) => {
    const updatedUnityInputs = [...unityInputs];
    updatedUnityInputs[index].unity = newValue;
    setUnityInputs(updatedUnityInputs);
  };

  const handleInvolvementChange = (event, index) => {
    const value = event.target.value;
    const updatedUnityInputs = [...unityInputs];
    updatedUnityInputs[index].involvement = value;
    setUnityInputs(updatedUnityInputs);
  };

  const handleAddUnity = () => {
    setUnityInputs([...unityInputs, { unity: null, involvement: "" }]);
  };

  useEffect(() => {

    const anywhere = [{value : '', label: 'Nenhum'}];

    countriesApi.get('/states')
    .then((res) => {
        const filtered = res.data.data.map((c) => {
            return {value: c.name, label: c.name};
        })
        setFilteredCountries(anywhere.concat(filtered));
    })
    .catch(error=> {})
  },[])

  const onNameChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setName((name) => ({
      ...name,
      [targetName]: targetValue
    }));
  };

  const onTypeChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setType((type) => ({
      ...type,
      [targetName]: targetValue
    }));
  };

  const onStartChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setStartDate((startDate) => ({
      ...startDate,
      [targetName]: targetValue
    }));
  };

  const onEndChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setEndDate((endDate) => ({
      ...endDate,
      [targetName]: targetValue
    }));
  };

  const onTargetChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setTargetAudience((targetAudience) => ({
      ...targetAudience,
      [targetName]: targetValue
    }));
  };

  const onCountryChange = (event, value) => {
    const selectedCountry = value ? value.label : "";
  
    setCountry((prevCountry) => ({
      ...prevCountry,
      country: selectedCountry,
    }));
  };

  const onLocationChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setLocation((location) => ({
      ...location,
      [targetName]: targetValue
    }));
  };

  const onDetailsChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setDetails((details) => ({
      ...details,
      [targetName]: targetValue
    }));
  };

  const validate = () => {
    if (!name.name || !type.type || !startDate.startDate || !endDate.endDate) 
      setError("Nome, tipo e datas são obrigatórios ");
    else if (new Date(startDate.startDate) > new Date(endDate.endDate))
      setError("Data de início deve ser antes da data de término"); 
    else {
      setError("");
      props.nextStep();
      props.userCallback(name);
      props.userCallback(type);
      props.userCallback(startDate);
      props.userCallback(endDate);
      props.userCallback(targetAudience);
      props.userCallback(country);
      props.userCallback(location);
      props.userCallback({unities: unityInputs});
      props.userCallback(details);
    }
  };

  return (
    <Card>
      <CardBody className='p-3'>
        <Row>
          <span style={{ color: "red" }}>{error}</span>
          <Col>
            <TextField 
              fullWidth
              label="Nome do Evento" 
              variant="standard" 
              name="name"
              onChange={onNameChange}
            />
          </Col>
        </Row>
        <Row className='my-3'>
          <Col className='mb-3'>
          <FormControl 
            fullWidth 
            variant="standard" 
            sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>Tipo</InputLabel>
            <Select
              label="Tipo"
              name="type"
              onChange={onTypeChange}
            >
              <MenuItem value="Interno">
                Interno
              </MenuItem>
              <MenuItem value="Externo">
                Externo
              </MenuItem>
            </Select>
          </FormControl>
          </Col>
          <Col>
          <InputLabel>Data Início</InputLabel>
            <TextField 
              fullWidth 
              type={'date'}
              variant="standard"
              name="startDate"
              onChange={onStartChange}
            />
          </Col>
          <Col>
          <InputLabel>Data Término</InputLabel>
            <TextField
              fullWidth 
              type={'date'} 
              variant="standard"
              name="endDate" 
              onChange={onEndChange}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} >
            <TextField 
              fullWidth
              label="Publico Alvo" 
              variant="standard"
              name="targetAudience" 
              onChange={onTargetChange}
            />
          </Col>
          <Col sm={4}>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
            <Autocomplete
              options={filteredCountries}
              onChange={onCountryChange}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Escolha um País"
                  variant="standard"
                />
              )}
            />
            </FormControl>
          </Col>
          <Col sm={4} >
            <TextField 
              fullWidth
              label="Local de Realização" 
              variant="standard"
              name="location" 
              onChange={onLocationChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField 
              fullWidth
              label="Detalhes" 
              variant="standard" 
              multiline
              rows={2}
              name="details"
              onChange={onDetailsChange}
            />
          </Col>
        </Row>
        <div>
            {unityInputs.map((input, index) => (
              <Row className="mb-3" key={index}>
                <Col md={4}>
                  <FormControl fullWidth variant="standard">
                  <Autocomplete
                    value={input.unity}
                    onChange={(event, newValue) => handleUnityChange(event, newValue, index)}
                    options={props.unities}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione Unidade" variant="standard" />
                    )}
                  />
                  </FormControl>
                </Col>
                <Col md={8}>
                  <TextField
                    label="Papel desempenhado pela unidade"
                    variant="standard"
                    fullWidth
                    name="involvement"
                    value={input.involvement}
                    onChange={(event) => handleInvolvementChange(event, index)}
                  />
                </Col>
              </Row>
            ))}
            <Button onClick={handleAddUnity}>Adicionar Mais Unidades</Button>
          </div>
        <Row>
          <ActionButtons {...props} nextStep={validate} />
        </Row>
      </CardBody>
    </Card>
  );
};


const Two = (props) => {

  const [partnerInputs, setPartnerInputs] = useState([
    { partner: null, involvement: "" },
  ]);

  const handlePartnerChange = (event, newValue, index) => {
    const updatedPartnerInputs = [...partnerInputs];
    updatedPartnerInputs[index].partner = newValue;
    setPartnerInputs(updatedPartnerInputs);
  };

  const handleInvolvementChange = (event, index) => {
    const value = event.target.value;
    const updatedPartnerInputs = [...partnerInputs];
    updatedPartnerInputs[index].involvement = value;
    setPartnerInputs(updatedPartnerInputs);
  };

  const handleAddPartner = () => {
    setPartnerInputs([...partnerInputs, { partner: null, involvement: "" }]);
  };

  const validate3 = () => {
    props.nextStep();
    props.userCallback({partners: partnerInputs});
  };

  return (
    <div>
      <Card>
        <CardBody className='p-3'>
        {partnerInputs.map((input, index) => (
            <div key={index}>
              <Row className='mb-3'>
                <Col>
                  <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
                  <Autocomplete
                    value={input.partner}
                    onChange={(event, newValue) => handlePartnerChange(event, newValue, index)}
                    options={props.partners}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Parceiro" variant="standard" />
                    )}
                  />
                  </FormControl>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <TextField 
                    label="Papel" 
                    variant="standard" 
                    name="partnerInvolvement"
                    fullWidth
                    value={input.involvement}
                    onChange={(event) => handleInvolvementChange(event, index)}
                  />
                </Col>
              </Row>
            </div>
          ))}
          <Row className='mb-3'>
            <Col>
              <div>
                <Button onClick={handleAddPartner} >Adicionar Novo Parceiro</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ActionButtons {...props} nextStep={validate3} />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const Three = (props) => {

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <Container>
      <Card>
        <CardBody className="p-3 m-3">
          <h3>Confirme os dados</h3>
          <br />
          <Row>
            <h4>Dados do Evento</h4>
            <Row>
              <Col>
                Titulo : <strong>{props.user.name}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Tipo : <strong>{props.user.type}</strong>
              </Col>
              <Col>
                Data De Início : <strong>{props.user.startDate}</strong>
              </Col>
              <Col>
                Data De Térmmino : <strong>{props.user.endDate}</strong>
              </Col>
            </Row>
            {
              props.user?.unities?.map((unity) => (
                <Row>
                <Col>
                  Unidade envolvida : <strong>{unity.unity.name}</strong>
                </Col>
                <Col>
                  Papel desempenhado : <strong>{unity.involvement}</strong>
                </Col>
                </Row>
              ))
            }
          </Row>
          <br />
          <Row>
            <Row>
              <Col>
                País : <strong>{props.user.country ? props.user.country : "Nenhum pais selecionado"}</strong>
              </Col>
              <Col>
                Local de Realização : <strong>{props.user.location ? props.user.location : "Sem localização definida"}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Detalhes : <p>{props.user.details}</p>
              </Col>
            </Row>
          </Row>
          <br />
          <Row>
            <h4>Parceria</h4>
            {
              props.user?.partners?.map((partner) => (
                <Row>
                  <Col>
                    Parceiro : <strong>{partner.partner.name}</strong>
                  </Col>
                  <Col>
                    Envolvimento : <strong>{partner.involvement}</strong>
                  </Col>
                </Row>
              ))
            }
          </Row>
        </CardBody>
      </Card>
    <br />
    <ActionButtons {...props} lastStep={handleLastStep} />
    </Container>
  );
}

const EventStepper = () => {

  const auth = useAuthHeader()

  const [unities, setUnities] = useState([]);
  const [partners, setPartners] = useState([]);

  const getUnities = async () => {
    
    const res = await api.get(`unities`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setUnities(res.data.unities)
  }

  const getPartners = async () => {
    
    const res = await api.get(`partners`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setPartners(res.data.partners)
  }

  useEffect(() => {
    getUnities();
    getPartners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  const [, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignUser = (val) => {
    setUser((user) => ({
      ...user,
      ...val
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleComplete = async () => {
    setLoading(true)
    try {
      await api.post('event', {
        name: user.name,
        type: user.type,
        start_date: user.startDate,
        target_audience : user.targetAudience,
        country : user.country,
        location: user.location,
        end_date: user.endDate,
        details : user.details,
        unities :
          user?.unities?.map((unity) => (
            {
              id: parseInt(unity.unity.id),
              involvement: unity.involvement
            }
          )),
        partners :
          user?.partners?.map((partner) => (
            {
              id: parseInt(partner.partner.id),
              involvement: partner.involvement
            }
          )),
      },
      {
        headers: {
          Authorization: auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      navigate('/events')
      setLoading(false)
      setMessage("Evento Adicionado com Sucesso");
      setShowAlert(true);
    }catch(error){
      setLoading(false);
      if(error.response){
        setMessage("Erro ao tentar Salvar");
        setShowAlert(true);
      }
    }
  };

  return (
   <>
    {
        loading ? (
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
          </Container>
        ) : (
          <Container>
            <MyAlert
              showAlert={showAlert}
              handleCloseAlert={handleCloseAlert}
              message={message}
            />
            <Stepper activeStep={activeStep}>
              <Step label="Dados Do Evento" children={<SummarizeIcon />} className={activeStep === 0 ? "bg-success" : ""} />
              <Step label="Parceiros" children={<Handshake />} className={activeStep === 1 ? "bg-success" : ""}/>
              <Step label="Confirmação" children={<Check/>} className={activeStep === 2 ? "bg-success" : ""} />
            </Stepper>
            <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
              <One userCallback={assignUser} unities={unities}/>
              <Two user={user} userCallback={assignUser} partners={partners}/>
              <Three user={user} completeCallback={handleComplete}/>
            </StepWizard>
          </Container>
        )
    }
   </>
  );
};

export default EventStepper;
