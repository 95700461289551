import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import FinanciersTable from './FinanciersTable'
import { useState } from 'react';

const FinanciersList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title={"Pesquise o nome do Financiador"} onSearch={handleSearch}/>
      <FinanciersTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle={"Adicionar Novo Financiador"} link={"new"}/>
    </>
  )
}

export default FinanciersList 