import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { KeyboardArrowDown, KeyboardArrowUp, Delete, Check } from '@mui/icons-material';
import Paginating from '../Paginating';
import api from '../../services/Api';
import { useState, useEffect } from 'react';
import Loading from '../Loading';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import MyDialog from '../../utils/MyDeleteDialog';
import { getValidationClassName } from '../../utils/validationUtils';
import { getDeleteButtonClassName } from '../../utils/deleteUtils';
import { handleValidation } from '../../utils/handleValidate';
import MyAlert from '../../utils/MyAlert';
import { FaEye } from 'react-icons/fa';


function Row(props) {

  const user = useAuthUser()
  const auth = useAuthHeader()

  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openValidationAlert, setOpenValidationAlert] = React.useState(false);
  const [validated, setValidated] = useState(false);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  
  const handleValidationClick = async () => {
    try {
      const validationSuccessful = await handleValidation(row, user, auth, 'project');
      if (validationSuccessful) {
        setValidated(true);
        setOpenValidationAlert(true);
      }else{
        setValidated(false)
        setOpenValidationAlert(true);
      }
    } catch (error) {
    } finally {
    }
  };

  const [display, setDisplay] = useState()

  useEffect(() => {
    validated ? setDisplay("d-none") : setDisplay()
  }, [validated])

  return (
    <React.Fragment>
      {openValidationAlert && (
          <MyAlert
            showAlert={true}
            handleCloseAlert={() => setOpenValidationAlert(false)}
            severity= { validated ? "success" : "error"}
            heading={ validated ? "Validação efectuada com sucesso!" : "Erro ao Validar!"}
            message={ validated ? "Projecto validado com sucesso!" : "Projecto não Validado"}
            autoDismissTime={3000}
          />
        )}
      <div>
        <MyDialog row={row} open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} url={'project'} title={'Projecto'} />
      </div>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
        <TableCell >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp  /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell>
          {
            row.unities.map((unity, index) => (
              <p key={index}>{unity.name} - <strong> <small> {unity.pivot.involvement}</small> </strong> </p>
            ))
          }
        </TableCell>
        <TableCell>{row.type==="Extensão" ? row.type+" - "+row.location : row.type}</TableCell>
        <TableCell>{row.total_amount} {row.currency}</TableCell>
        <TableCell>{row.start_date}</TableCell>
        <TableCell>{row.end_date}</TableCell>
        <TableCell>{(row.validation && row.validation2) ? "Validado" : ((row.validation && !row.validation2) || (!row.validation && row.validation2)) ? "1/2" : "0/2" }</TableCell>
        <TableCell>
          <div className='d-flex' >
            <Tooltip title="Validar " arrow
              className={ display ? display : getValidationClassName(user, row) } 
              onClick={() => handleValidationClick(row, user, auth)}>
              <IconButton
                aria-expanded="false"
                size="small"
              >
              <Check style={{ color: "green" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver Detalhes" arrow onClick={ () => setOpen(!open)}>
              <IconButton
                aria-expanded="false"
                size="small"
              >
              <FaEye style={{ color: "green" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Apagar Projecto" arrow
              className={display ? display : getDeleteButtonClassName(user(), row)}>
              <IconButton
                aria-expanded="false"
                size="small"
                onClick={handleDeleteClick}
              >
              <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className="bg-light">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft:70 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalhes
              </Typography>
              <Typography gutterBottom component="div">
                <strong> Cordenador do projecto: <small> {row.coordinator} </small> </strong>
              </Typography>
              <Typography gutterBottom component="div">
                <strong> <small>  Financiador(es) </small> </strong>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow style={{ backgroundColor: 'InfoBackground' }}>
                    <TableCell/>
                    <TableCell>Nome</TableCell>
                    <TableCell>País</TableCell>
                    <TableCell>Valor Financiado</TableCell>
                    <TableCell>Moeda</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>E-mail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.financiers?.map((financier, index) => (
                    <TableRow key={index}>
                      <TableCell/>
                      <TableCell >
                        {financier.name}
                      </TableCell>
                      <TableCell>{financier.country}</TableCell>
                      <TableCell>{financier.pivot.total_amount}</TableCell>
                      <TableCell>{financier.pivot.currency}</TableCell>
                      <TableCell>{financier.phone_number}</TableCell>
                      <TableCell>{financier.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography gutterBottom component="div">
                <strong> <small>  Parceiros(s) </small> </strong>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow style={{ backgroundColor: 'InfoBackground'}}>
                    <TableCell/>
                    <TableCell>Nome</TableCell>
                    <TableCell>Apelido</TableCell>
                    <TableCell>Envolvimento</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Tipo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.partners?.map((partner, index) => (
                    <TableRow key={index}>
                      <TableCell/>
                      <TableCell >
                        {partner.name}
                      </TableCell>
                      <TableCell>{partner.last_name}</TableCell>
                      <TableCell>{partner.pivot.involvement}</TableCell>
                      <TableCell>{partner.email}</TableCell>
                      <TableCell>{partner.type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable({searchKey}) {

  const auth = useAuthHeader()

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  const getData = async (page) => {
    
    const res = await api.get(`projects?page=${page}`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setData(res.data.projects)
    setTotalPages(res.data.projects.last_page)
    setLoading(false)
  }

  const getSearchData = async (searchKey ,page) => {
    
    const res = await api.get(`projects/${searchKey}?page=${page}`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setData(res.data.projects)
    setTotalPages(res.data.projects.last_page)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if (searchKey !== '') {
      getSearchData(searchKey, currentPage)
    }else
      getData(currentPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchKey])


  return (
    <>
      { loading ? (
        <Loading/>
      ) : (
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead className='bg-success'>
            <TableRow>
              <TableCell/>
              <TableCell className='text-light'><strong>Título</strong></TableCell>
              <TableCell className='text-light'><strong>Unidade(s)</strong></TableCell>
              <TableCell className='text-light'><strong>Tipo de Projecto</strong></TableCell>
              <TableCell className='text-light'><strong>Valor Total</strong></TableCell>
              <TableCell className='text-light'><strong>Data de Início</strong></TableCell>
              <TableCell className='text-light'><strong>Data de Conclusão</strong></TableCell>
              <TableCell className='text-light'><strong>Validações</strong></TableCell>
              <TableCell align="right" className='text-light'><strong>Acções</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data?.data?.map((item, index) => (
                <Row key={index} row={item} data={[item]}/>
              ))
            }
          </TableBody>
        </Table>
        <Paginating currentPage={currentPage} total={totalPages} onPageChange={(page) => setCurrentPage(page)}/>
      </TableContainer>
      )}
  </> 
  );
}