import React from 'react'
import Search from '../Search'
import CollapsibleTable from './CollapsibleTable'
import FloatingAddButton from '../../utils/FloatingAddButton';
import { useState } from 'react';

const EventsList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title= {"Pesquise por unidade, tipo ou título do Evento"} onSearch={handleSearch}/>
      <CollapsibleTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle="Adicionar novo Evento" link='new'/>
    </>
  )
}

export default EventsList