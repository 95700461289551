import React from 'react'
import Sidebar from '../Sidebar'
import Navmenu from '../Navmenu'
import { Outlet } from 'react-router'
import { Row, Col } from 'reactstrap'
import Footer from '../Footer'

const Sidebardash = () => {
  return (
    <div>
      <Navmenu/>
      <Row className='d-flex flex-nowrap' >
        <Col className='col-auto'>
          <Sidebar/>
        </Col>
        <Col style={{ maxWidth: '82%' }}>
          <Outlet/>
        </Col>
      </Row>
      <Footer/>
    </div>
  )
}

export default Sidebardash