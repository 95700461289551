import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import StudentsTable from './StudentsTable'
import { useState } from 'react';

const StudentsList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title={"Pesquise o nome ou numero do Estudante"} onSearch={handleSearch}/>
      <StudentsTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle={"Adicionar Novo Estudante"} link={"new"}/>
    </>
  )
}

export default StudentsList 