import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';


const CoursesForm = ({course}) => {

  const auth = useAuthHeader()
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Alerta");
  const [severity, setSeverity] = useState("info");

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [name, setName] = useState(course?.name || '');
  const [duration, setDuration] = useState(course?.duration || '');
  const [academicLevel, setAcademicLevel] = useState(course?.degree || '');

  const academiclevels = [
    {id : '1', name : 'Mestrado'},
    {id : '2', name : 'Licenciado'},
    {id : '3', name : 'Doutorado'},
    {id : '4', name : 'Bacharelato'},
    {id : '5', name : 'Catedrático'}
  ]

  const handleSave = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.post('course', {
        name, 
        duration,
        degree : academicLevel
      },
      {
        headers: {
          Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("Curso Adicionado com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/courses')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }

  const handleEdit = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.put(`course/${course.id}`, {
        name, 
        duration,
        degree : academicLevel
      },
      {
        headers: {
          Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("Alterações efectuadas com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/courses')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }


  return (
    loading ? (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress color="success" /> 
      </Container>
    ) : (
    <Form style={{ height: "50vh" }} className='d-flex justify-content-center align-items-center'>
      <MyAlert
        showAlert={showAlert}
        handleCloseAlert={handleCloseAlert}
        message={message}
        heading={heading}
        severity={severity}
      />
      <Card >
        <CardBody className='p-3'>
          <Row>
            <Col md={8}>
              <TextField 
              fullWidth
              label="Nome do curso" 
              variant="standard" 
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col md={4}>
              <TextField 
              fullWidth
              type='number'
              label="Duração em anos" 
              variant="standard" 
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
              <InputLabel>Grau</InputLabel>
              <Select
                label="Grau"
                onChange={(e) => setAcademicLevel(e.target.value)}
                value={academicLevel}
                >
                {
                    academiclevels.map((level, index) => (
                    <MenuItem value={level.name} id={index}> 
                        {level.name} 
                    </MenuItem>
                    ))
                }
              </Select>
              </FormControl>
            </Col>
          </Row>
          <Row className="text-end">
              <Col>
              {!course?.name ? (
                <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
              ) : (
                <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
              )}
              </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
    )
  )
}

export default CoursesForm