import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import '../utils/MyAlert.css';

const MyAlert = ({ showAlert, handleCloseAlert, severity = "info", heading = "Alerta", message = "Mensagem", autoDismissTime = 3000 }) => {
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        handleCloseAlert();
      }, autoDismissTime);
      return () => clearTimeout(timer);
    }
  }, [showAlert, handleCloseAlert, autoDismissTime]);

  return (
    <>
      {showAlert && (
        <Alert className='fixed-alert' variant='filled' severity={severity}>
          <AlertTitle>{heading}</AlertTitle>
          {message && <strong>{message}</strong>}
        </Alert>
      )}
    </>
  );
};

export default MyAlert;