import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import './FloatingAddButton.css';
import React from 'react'
import { Link } from 'react-router-dom';

const FloatingAddButton = ({toolTitle, link}) => {

  return (
    <Link to={link}>
      <Fab className='bg-success fix' 
    style={{ position: 'fixed', color: 'white', bottom: 170, right: 20 }}
    aria-label="add">
        <Tooltip title={toolTitle} arrow>
          <AddIcon />
        </Tooltip>
      </Fab>
    </Link>
  )
}

export default FloatingAddButton