import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import UnitiesTable from './UnitiesTable';
import { useAuthUser } from 'react-auth-kit';
import { useState } from 'react';

const UnitiesList = () => {

  const user = useAuthUser();
  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
        <Search title={"Pesquise o nome da unidade"} onSearch={handleSearch}/>
        <UnitiesTable searchKey={searchKey}/>
        <div className={ user()?.data?.roles[0].id === 4 ? '' : 'd-none' }>
          <FloatingAddButton toolTitle={"Adicionar nova Unidade"} link={"new"}/>
        </div>
    </>
  )
}

export default UnitiesList