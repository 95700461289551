import { Box, Card, CardActionArea, CardContent, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Col, Row } from 'reactstrap'
import api from '../../services/Api';
import { useAuthHeader } from 'react-auth-kit';

const ReportsData = () => {

    const auth = useAuthHeader();
    const [reportData, setReportData] = useState([])
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const generatePDF = (entity) => {
      const printContent = document.getElementById(entity);
      if (printContent) {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <style>
                table {
                  border-collapse: collapse;
                  width: 100%;
                }
    
                table, th, td {
                  border: 1px solid black;
                }
                h1 {
                  text-align: center;
                  margin-bottom: 20px;
                }
              </style>
            </head>
            <body>
              <h3>Faculdade de Ciências</h3>
              ${printContent.innerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
      }
    };    

    const fetchData = async (url) => {
      if (start && end) {
        try {
          const res = await api.get(`/${url}/${start}/${end}`, {
            headers: {
              Authorization: auth(),
            },
          });

          setReportData(res.data);
            setTimeout(() => {
              generatePDF(url);
            }, 3000);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const projectReport = async () => {
      await fetchData('projects')
    }

    const articleReport = async () => {
      await fetchData('articles');
    }

    const eventReport = async () => {
      await fetchData('events');
    }

    const workReport = async () => {
      await fetchData('works');
    }

    const data = [
      { id: 1, name: 'Projectos', text: 'Clique aqui para ter o relatório de projectos', action: projectReport },
      { id: 2, name: 'Obras e Artigos', text: 'Clique aqui para ter o relatório de Obras e Artigos', action: articleReport },
      { id: 3, name: 'Eventos', text: 'Clique aqui para ter o relatório de Eventos', action: eventReport },
      { id: 4, name: 'Trabalhos', text: 'Clique aqui para ter o relatório de trabalhos', action: workReport },
    ]


    return (
      <div>
        <div id='projects' className='d-none'>
          <table>
            <thead>
              <tr>
                <th className='text-light'><strong>Título</strong></th>
                <th className='text-light'><strong>Unidade(s)</strong></th>
                <th className='text-light'><strong>Tipo de Projecto</strong></th>
                <th className='text-light'><strong>Valor Total</strong></th>
                <th className='text-light'><strong>Data de Início</strong></th>
                <th className='text-light'><strong>Data de Conclusão</strong></th>
              </tr>
            </thead>
            <tbody>
              {reportData?.map((item, index) => (
                    <>
                    <tr className='bg-gray'>
                      <td component="th">{item?.title}</td>
                      <td>
                        {item?.unities?.map((unity, index) => (
                          <p key={index}>
                            {unity?.name} - <strong> <small> {unity.pivot.involvement}</small> </strong>
                          </p>
                        ))}
                      </td>
                      <td>{item?.type === "Extensão" ? item?.type + " - " + item?.location : item?.type}</td>
                      <td>{item?.total_amount} {item?.currency}</td>
                      <td>{item?.start_date}</td>
                      <td>{item?.end_date}</td>
                    </tr>
                    <tr className="bg-light">
                      <td style={{ paddingBottom: 0, paddingTop: 0, paddingLeft:70 }} colSpan={9}>
              
                          <div sx={{ margin: 1 }}>
                            <p variant="h6" gutterBottom component="div">
                              Detalhes
                            </p>
                            <p gutterBottom component="div">
                              <strong> Cordenador do projecto: <small> {item?.coordinator} </small> </strong>
                            </p>
                            <p gutterBottom component="div">
                              <strong> <small>  Financiador(es) </small> </strong>
                            </p>
                            <table size="small" aria-label="purchases">
                              <tr>
                                  <th>Nome</th>
                                  <th>País</th>
                                  <th>Valor Financiado</th>
                                  <th>Moeda</th>
                                  <th>Telefone</th>
                                  <th>E-mail</th>
                              </tr>
                              <tr>
                                  {item?.financiers?.map((financier) => (
                                      <React.Fragment key={financier.id}>
                                      <td>{financier.name}</td>
                                      <td>{financier.country}</td>
                                      <td>{financier.pivot.total_amount}</td>
                                      <td>{financier.pivot.currency}</td>
                                      <td>{financier.phone_number}</td>
                                      <td>{financier.email}</td>
                                      </React.Fragment>
                                  ))}
                                  </tr>
                            </table>
                            <div>
                              <strong> <small>  Parceiros(s) </small> </strong>
                            </div>
                            <table size="small" aria-label="purchases">
                              <tr>
                                  <th>Nome</th>
                                  <th>Apelido</th>
                                  <th>Envolvimento</th>
                                  <th>E-mail</th>
                                  <th>Tipo</th>
                              </tr>
                              <tr>
                              {item?.partners?.map((partner, index) => (
                                  <tr key={index}>
                                      <td>{partner.name}</td>
                                      <td>{partner.last_name}</td>
                                      <td>{partner.pivot.involvement}</td>
                                      <td>{partner.email}</td>
                                      <td>{partner.type}</td>
                                  </tr>
                                  ))}
                              </tr>
                              <strong> Projecto Registado por: <small> {item?.user?.name} {item?.user?.last_name} </small> </strong>
                            </table>
                          </div>
                      </td>
                    </tr>
                  </>
              ))}
            </tbody>
          <div className='m-2 p-1'>
            <strong> Relatório de Projectos relativo ao período de: {start} a {end}, gerado a {new Date().toUTCString()} </strong>
          </div>
        </table>
        </div>
        <div id='articles' className='d-none'>
          <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead className='bg-success'>
              <TableRow>
                <TableCell className='text-light'><strong>Título</strong></TableCell>
                <TableCell className='text-light'><strong>Autor(es)</strong></TableCell>
                <TableCell className='text-light'><strong>Tipo</strong></TableCell>
                <TableCell className='text-light'><strong>Data de publicação</strong></TableCell>
                <TableCell className='text-light'><strong>Revisao por pares</strong></TableCell>
                <TableCell className='text-light'><strong>Info Diversa</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                reportData?.map((item, index) => (
                  <>
                  <TableRow >
                    <TableCell >
                      <strong> {item?.title?.split(",")[0]}</strong>{item?.title?.split(",")[1] ? ", "+item?.title?.split(",")[1] : ""}
                    </TableCell>
                    <TableCell>
                      {
                        item?.autors?.map((autor, index) => (
                          <p key={index}>{autor.lastname} {autor.name} - <strong> <small> {autor.pivot.involvement}</small> </strong> </p>
                        ))
                      }
                    </TableCell>
                    <TableCell>{item?.type}</TableCell>
                    <TableCell>{item?.date_published}</TableCell>
                    <TableCell>{item?.peer_review ? "Revisado" : "Não Revisado"}</TableCell>
                    <TableCell>{item?.edition ? item?.edition+" ª Edição, " : ""} {item?.publisher ? item?.publisher+", " : ""} {item?.place ? item?.place+", ": ""} {item?.pages_number} Páginas </TableCell>
                  </TableRow>
                  <TableRow className="bg-light">
                    <TableCell colSpan={6}>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Detalhes
                          </Typography>
                          <Typography gutterBottom component="div">
                            <strong> <small>  Autor(es) </small> </strong>
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Apelido</TableCell>
                                <TableCell>País de Origem</TableCell>
                                <TableCell>Nivel académico</TableCell>
                                <TableCell>Orcid</TableCell>
                                <TableCell>E-mail</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.autors?.map((autor, index) => (
                                <TableRow key={index}>
                                  <TableCell/>
                                  <TableCell >
                                    {autor.name}
                                  </TableCell>
                                  <TableCell>{autor.last_name}</TableCell>
                                  <TableCell>{autor.country}</TableCell>
                                  <TableCell>{autor.academic_level}</TableCell>
                                  <TableCell>{autor.orcid}</TableCell>
                                  <TableCell>{autor.email}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Typography gutterBottom component="div">
                            <strong> <small>  Sobre a Obra(s) </small> </strong>
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Link</TableCell>
                                <TableCell>Citação</TableCell>
                                <TableCell>Palavras Chave</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                              reportData?.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell >
                                    {data.link}
                                  </TableCell>
                                  <TableCell>{data.citation}</TableCell>
                                  <TableCell>{data.keywords}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <strong> Artigo Registado por: <small> {item?.user?.name} {item?.user?.last_name} </small> </strong>
                        </Box>
                    </TableCell>
                  </TableRow>
                  </>
                ))
              }
            </TableBody>
          </Table>
          <div className='m-2 p-1'>
          <strong> Relatório de Obras/Artigos relativo ao período de: {start} a {end}, gerado a {new Date().toUTCString()} </strong>
        </div>
        </TableContainer>
        </div>
        <div id='events' className='d-none'>
          <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead className='bg-success'>
              <TableRow>
                <TableCell className='text-light'><strong>Nome</strong></TableCell>
                <TableCell className='text-light'><strong>Unidade(s)</strong></TableCell>
                <TableCell className='text-light'><strong>Tipo de Evento</strong></TableCell>
                <TableCell className='text-light'><strong>Público Alvo</strong></TableCell>
                <TableCell className='text-light'><strong>País</strong></TableCell>
                <TableCell className='text-light'><strong>Data de Início</strong></TableCell>
                <TableCell className='text-light'><strong>Data de Fim</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                reportData?.map((row, index) => (
                  <>
                  <TableRow>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {
                        row?.unities?.map((unity, index) => (
                          <p key={index}>{unity.name} - <strong> <small> {unity.pivot.involvement}</small> </strong> </p>
                        ))
                      }
                    </TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.target_audience}</TableCell>
                    <TableCell>{row.country+', '+row.location}</TableCell>
                    <TableCell>{row.start_date}</TableCell>
                    <TableCell>{row.end_date}</TableCell>
                    </TableRow>
                    <TableRow className="bg-light">
                    <TableCell colSpan={6}>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Mais Informações
                          </Typography>
                          <Typography gutterBottom component="div" className={row.details ? "" : "d-none"}>
                            <strong> Detalhes/Observações: <small> {row.details} </small> </strong>
                          </Typography>
                          <Typography gutterBottom component="div">
                            <strong> <small>  Parceiros(s) </small> </strong>
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow style={{ backgroundColor: 'InfoBackground'}}>
                                <TableCell>Nome</TableCell>
                                <TableCell>Apelido</TableCell>
                                <TableCell>Envolvimento</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Tipo</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row?.partners?.map((partner, index) => (
                                <TableRow key={index}>
                                  <TableCell >
                                    {partner.name}
                                  </TableCell>
                                  <TableCell>{partner.last_name}</TableCell>
                                  <TableCell>{partner.pivot.involvement}</TableCell>
                                  <TableCell>{partner.email}</TableCell>
                                  <TableCell>{partner.type}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <strong> Evento Registado por: <small> {row.user?.name} {row.user?.last_name} </small> </strong>
                        </Box>
                    </TableCell>
                  </TableRow>
                  </>
                ))
              }
            </TableBody>
            </Table>
            <Typography>
          <strong>
              Relatório de Eventos relativo ao período de: {start} a {end}, gerado a {new Date().toUTCString()}
          </strong>
          </Typography>
      </TableContainer>
        </div>
        <div id='works' className='d-none'>
          <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead className='bg-success'>
              <TableRow>
                <TableCell className='text-light'><strong>Título</strong></TableCell>
                <TableCell className='text-light'><strong>Autor</strong></TableCell>
                <TableCell className='text-light'><strong>Área</strong></TableCell>
                <TableCell className='text-light'><strong>Data de Apresentação</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.map((row, index) => (
                <>
                  <TableRow>
                  <TableCell>
                    {row?.title}
                  </TableCell>
                  <TableCell>
                    <p>{row?.student?.last_name} - <strong> <small> {row?.student?.name}</small> </strong> </p>
                  </TableCell>
                  <TableCell>{row?.area}</TableCell>
                  <TableCell>{row?.date_presented}</TableCell>
                </TableRow>
                <TableRow className="bg-light">
                  <TableCell colSpan={6}>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Detalhes
                      </Typography>
                      <Typography gutterBottom component="div">
                        <strong> <small>  Autor </small> </strong>
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow style={{ backgroundColor: 'InfoBackground' }}>
                            <TableCell>Nome</TableCell>
                            <TableCell>Apelido</TableCell>
                            <TableCell>Numero Estudante</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Curso</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {row?.student?.name}
                            </TableCell>
                            <TableCell>{row?.student?.last_name}</TableCell>
                            <TableCell>{row?.student?.number}</TableCell>
                            <TableCell>{row?.student?.email}</TableCell>
                            <TableCell>{row?.student?.course.name}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Typography gutterBottom component="div">
                        <strong> <small>  Supervisor(es) </small> </strong>
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow style={{ backgroundColor: 'InfoBackground' }}>
                            <TableCell>Nome</TableCell>
                            <TableCell>Apelido</TableCell>
                            <TableCell>Envolvimento</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Nível Académico</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row?.supervisors?.map((supervisor, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {supervisor.name}
                              </TableCell>
                              <TableCell>{supervisor.last_name}</TableCell>
                              <TableCell>{supervisor.pivot.involvement}</TableCell>
                              <TableCell>{supervisor.email}</TableCell>
                              <TableCell>{supervisor.type}</TableCell>
                              <TableCell>{supervisor.academic_level}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <strong> Trabalho Registado por: <small> {row?.user?.name} {row?.user?.last_name} </small> </strong>
                    </Box>
                  </TableCell>
                </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
          <Typography>
            <strong>
              Relatório de Trabalhos relativo ao período de: {start} a {end}, gerado a {new Date().toUTCString()}
            </strong>
          </Typography>
        </TableContainer>
        </div>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col md={6} className='text-center'>
          <Card>
            <CardContent>
                <h5>
                  Selecione a data de Início e de Término do Relatório que deseja Reproduzir! Selecionado o período, clique no Relatório que deseja! 
                </h5>
              </CardContent>
          </Card>
          </Col>
        </Row>

        <Card className='p-2 m-2 col-md-6 mx-auto'>
          <CardContent>
          <Row className=''>
            <Col>
            <InputLabel>Escolha data de início</InputLabel>
              <TextField
                fullWidth 
                type={'date'} 
                variant="standard"
                name="endDate" 
                value={start}
                onChange={(e) => setStart(e.target.value)}
              />
            </Col>
            <Col>
              <InputLabel>Data Término</InputLabel>
              <TextField
                fullWidth 
                type={'date'} 
                variant="standard"
                name="endDate" 
                value={end}
                onChange={(e) => setEnd(e.target.value)}
              />
            </Col>
          </Row>
          </CardContent>
        </Card>
        { start && end ? (
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {
            data.map((item, index) => (
              <Col className="mb-3" md={6} lg={3}>
                <Card >
                  <CardActionArea onClick={item.action}>
                    <CardContent>
                      <Typography className="text-center" sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                        {item.name}
                      </Typography>
                      <Typography className="text-center" variant="h6" component="div">
                        {item.text}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Col>
            ))
          }
          </Row>
        ): (
          <div>
            
          </div>
        )}
    </div>
  )
}

export default ReportsData