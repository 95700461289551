import React from 'react';
import { useLocation } from 'react-router-dom';
import UsersForm from './UsersForm';

const UserEdit = () => {
  const location = useLocation();
  const user = location.state?.user;

  return <UsersForm user={user} />;
};

export default UserEdit;
