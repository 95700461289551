import React from 'react'
import Title from '../components/dashContent/Title'
import { Outlet } from 'react-router-dom';

const Autors = () => {
  return (
    <div className='m-1'>
        <Title text="Autores"/>
        <Outlet>
            
        </Outlet>
    </div>
  )
}

export default Autors