export const getDeleteButtonClassName = (userData, row) => {
  const roleId = userData?.data?.roles[0].id;
  const userId = userData?.data?.id;

  if ((roleId !== 4 && row.validation && row.validation2) ||
      (roleId === 3 && row.validation2) || (userId === row.user_id && (row.validation || row.validation2))) {
    return 'd-none';
  } else {
    return '';
  }
};
  