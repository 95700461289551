import React from 'react'
import Title from '../components/dashContent/Title'
import { Outlet } from 'react-router-dom';

const Supervisors = () => {
  return (
    <div className='m-1'>
      <Title text="Supervisores"/>
      <Outlet>
        
      </Outlet>
    </div>
  )
}

export default Supervisors