import React, { useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import SummarizeIcon from '@mui/icons-material/Summarize';
import StepWizard from "react-step-wizard";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Row, Col, Button, CardBody, Card, Container } from "reactstrap";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../services/Api';
import { useAuthHeader } from 'react-auth-kit';
import { Check, Handshake, Paid } from "@mui/icons-material";
import MyAlert from "../../utils/MyAlert";
import { useNavigate } from 'react-router-dom';

const currencys = [
  {symbol : '$', currency : 'USD', label : 'Dólar Americano'},
  {symbol : 'R', currency : 'ZAR', label : 'Rand Sul Africano'},
  {symbol : 'MT', currency : 'MZN', label : 'Metical Moçambicano'},
  {symbol : '€', currency : 'EUR', label : 'Euro'},
  {symbol : '£', currency : 'GBP', label : 'Libra Esterlina'},
]

const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div>
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <IconButton color="success" onClick={handleBack}>
              <ArrowBackIosNewIcon/>
            </IconButton>
          </Col>
        )}
        <Col className="text-end">
          {props.currentStep < props.totalSteps && (
            <IconButton color="success" onClick={handleNext}>
              <ArrowForwardIosIcon/>
            </IconButton>
          )}
          {props.currentStep === props.totalSteps && (
            <Button className="bg-success" onClick={handleFinish}>Guardar</Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const One = (props) => {
  const [title, setTitle] = useState({});
  const [type, setType] = useState({});
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [amount, setAmount] = useState({});
  const [currency, setCurrency] = useState({});
  const [coordinator, setCoordinator] = useState({})
  const [location, setLocation] = useState({});

  const [error, setError] = useState("");

  const [unityInputs, setUnityInputs] = useState([
    { unity: null, involvement: "" },
  ]);

  const handleUnityChange = (event, newValue, index) => {
    const updatedUnityInputs = [...unityInputs];
    updatedUnityInputs[index].unity = newValue;
    setUnityInputs(updatedUnityInputs);
  };

  const handleInvolvementChange = (event, index) => {
    const value = event.target.value;
    const updatedUnityInputs = [...unityInputs];
    updatedUnityInputs[index].involvement = value;
    setUnityInputs(updatedUnityInputs);
  };

  const handleAddUnity = () => {
    setUnityInputs([...unityInputs, { unity: null, involvement: "" }]);
  };

  const onTitleChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setTitle((title) => ({
      ...title,
      [targetName]: targetValue
    }));
  };

  const onCoordinatorChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setCoordinator((coordinator) => ({
      ...coordinator,
      [targetName]: targetValue
    }));
  };

  const onLocationChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setLocation((location) => ({
      ...location,
      [targetName]: targetValue
    }));
  };

  const onTypeChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setType((type) => ({
      ...type,
      [targetName]: targetValue
    }));
  };

  const onStartChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setStartDate((startDate) => ({
      ...startDate,
      [targetName]: targetValue
    }));
  };

  const onEndChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setEndDate((endDate) => ({
      ...endDate,
      [targetName]: targetValue
    }));
  };

  const onAmountChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setAmount((amount) => ({
      ...amount,
      [targetName]: targetValue
    }));
  };

  const onCurrencyChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setCurrency((currency) => ({
      ...currency,
      [targetName]: targetValue
    }));
  };

  const [extension, setExtension] = useState(false);
  useEffect(() => {
    setExtension(type.type === "Extensão")
  }, [type])
  

  const validate = () => {
    if (!title.title || !startDate.startDate || !endDate.endDate ) 
      setError("Titulo e datas são obrigatórios");
    else if (new Date(startDate.startDate) > new Date(endDate.endDate))
      setError("Data de início deve ser antes da data de término");
    else {
      setError("");
      props.nextStep();
      props.userCallback(title);
      props.userCallback(type);
      props.userCallback(startDate);
      props.userCallback(endDate);
      props.userCallback(currency);
      props.userCallback(amount);
      props.userCallback({unities: unityInputs});
      props.userCallback(coordinator);
      props.userCallback(location);
    }
  };

  return (
    <Card>
      <CardBody className='p-3'>
        <Row>
          <span style={{ color: "red" }}>{error}</span>
          <Col>
            <TextField 
              fullWidth
              label="Título" 
              variant="standard" 
              name="title"
              onChange={onTitleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label="Cordenador do Projecto" 
              variant="standard" 
              fullWidth 
              name="coordinator"
              onChange={onCoordinatorChange}
            />
          </Col>
        </Row>
        <Row className='my-3'>
          <Col className='mb-3'>
          <FormControl 
            fullWidth 
            variant="standard" 
            sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>Tipo</InputLabel>
            <Select
              label="Tipo"
              name="type"
              onChange={onTypeChange}
            >
              <MenuItem value="Investigação">
                Investigação
              </MenuItem>
              <MenuItem value="Extensão">
                Extensão
              </MenuItem>
              <MenuItem value="Outro">
                Outro
              </MenuItem>
            </Select>
          </FormControl>
          </Col>
          <Col>
          <InputLabel>Data Início</InputLabel>
            <TextField 
              fullWidth 
              type={'date'}
              variant="standard"
              name="startDate"
              onChange={onStartChange}
            />
          </Col>
          <Col>
          <InputLabel>Data Término</InputLabel>
            <TextField
              fullWidth 
              type={'date'} 
              variant="standard"
              name="endDate" 
              onChange={onEndChange}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} >
            <TextField 
              fullWidth type={'number'} 
              step="0.01" 
              label="Valor" 
              variant="standard"
              name="amount" 
              onChange={onAmountChange}
            />
          </Col>
          <Col sm={8}>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
            <InputLabel>Moeda</InputLabel>
            <Select
              label="Moeda"
              onChange={onCurrencyChange}
              name="currency"
            >
              {
                currencys.map((currency, index) => (
                  <MenuItem value={currency.currency} id={index}> 
                    {currency.label} - {currency.currency}({currency.symbol}) 
                  </MenuItem>
                ))
              }
            </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className={extension ? "" : "d-none"}>
          <Col>
            <TextField
              label="Localização" 
              variant="standard" 
              fullWidth 
              name="location"
              onChange={onLocationChange}
            />
          </Col>
        </Row>
        <div>
          {unityInputs.map((input, index) => (
            <Row className="mb-3" key={index}>
              <Col md={4}>
                <FormControl fullWidth variant="standard">
                <Autocomplete
                    value={input.unity}
                    onChange={(event, newValue) => handleUnityChange(event, newValue, index)}
                    options={props.unities}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione Unidade Envolvida" variant="standard" />
                    )}
                  />
                </FormControl>
              </Col>
              <Col md={8}>
                <TextField
                  label="Papel desempenhado pela unidade"
                  variant="standard"
                  fullWidth
                  name="involvement"
                  value={input.involvement}
                  onChange={(event) => handleInvolvementChange(event, index)}
                />
              </Col>
            </Row>
          ))}
          <Button onClick={handleAddUnity}>Adicionar Mais Unidades</Button>
        </div>
        <Row>
          <ActionButtons {...props} nextStep={validate} />
        </Row>
      </CardBody>
    </Card>
  );
};

const Two = (props) => {

  const [financierInputs, setFinancierInputs] = useState([
    { financier: null, amount: "", currency: "" },
  ]);

  const handleFinancierChange = (event, newValue, index) => {
    const updatedFinancierInputs = [...financierInputs];
    updatedFinancierInputs[index].financier = newValue;
    setFinancierInputs(updatedFinancierInputs);
  };

  const handleAmountChange = (event, index) => {
    const value = event.target.value;
    const updatedFinancierInputs = [...financierInputs];
    updatedFinancierInputs[index].amount = value;
    setFinancierInputs(updatedFinancierInputs);
  };

  const handleCurrencyChange = (event, index) => {
    const value = event.target.value;
    const updatedFinancierInputs = [...financierInputs];
    updatedFinancierInputs[index].currency = value;
    setFinancierInputs(updatedFinancierInputs);
  };

  const handleAddFinancier = () => {
    setFinancierInputs([...financierInputs, { financier: null, amount: "", currency: "" }]);
  };

  const validate2 = () => {
    props.nextStep();
    props.userCallback({financiers: financierInputs})
  };

  return (
    <div>
      <Card>
        <CardBody className='p-3'>
          {financierInputs.map((input, index) => (
            <div>
              <Row className='mb-3'>
                <Col>
                  <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
                  <Autocomplete
                    value={input.financier}
                    onChange={(event, newValue) => handleFinancierChange(event, newValue, index)}
                    options={props.financiers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Financiador" variant="standard" />
                    )}
                  />
                    </FormControl>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={4}>
                  <TextField 
                    fullWidth type={'number'} 
                    step="0.01" 
                    label="Valor" 
                    variant="standard"
                    name="financierAmount" 
                    value={input.amount}
                    onChange={(event) => handleAmountChange(event, index)}
                  />
                </Col>
                <Col md={8}>
                  <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
                    <InputLabel>Moeda</InputLabel>
                    <Select
                      label="Moeda"
                      name="financierCurrency"
                      value={input.currency}
                      onChange={(event) => handleCurrencyChange(event, index)}
                    >
                      {
                        currencys.map((currency, index) => (
                          <MenuItem value={currency.currency} id={index}> 
                            {currency.label} - {currency.currency}({currency.symbol}) 
                          </MenuItem>
                        ))
                      }
                    </Select>
                    </FormControl>
                </Col>
              </Row>
            </div>
          ))}
          <Row className='mb-3'>
            <Col>
              <div>
                <Button onClick={handleAddFinancier}>Adicionar Mais Financiadores</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ActionButtons {...props} nextStep={validate2} />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const Three = (props) => {

  const [partnerInputs, setPartnerInputs] = useState([
    { partner: null, involvement: "" },
  ]);

  const handlePartnerChange = (event, newValue, index) => {
    const updatedPartnerInputs = [...partnerInputs];
    updatedPartnerInputs[index].partner = newValue;
    setPartnerInputs(updatedPartnerInputs);
  };

  const handleInvolvementChange = (event, index) => {
    const value = event.target.value;
    const updatedPartnerInputs = [...partnerInputs];
    updatedPartnerInputs[index].involvement = value;
    setPartnerInputs(updatedPartnerInputs);
  };

  const handleAddPartner = () => {
    setPartnerInputs([...partnerInputs, { partner: null, involvement: "" }]);
  };

  const validate3 = () => {
    props.nextStep();
    props.userCallback({partners: partnerInputs});
  };

  return (
    <div>
      <Card>
        <CardBody className='p-3'>
          {partnerInputs.map((input, index) => (
            <div key={index}>
              <Row className='mb-3'>
                <Col>
                  <FormControl fullWidth variant="standard" sx={{ minWidth: 250 }}>
                  <Autocomplete
                    value={input.partner}
                    onChange={(event, newValue) => handlePartnerChange(event, newValue, index)}
                    options={props.partners}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Parceiro" variant="standard" />
                    )}
                  />
                  </FormControl>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <TextField 
                    label="Papel" 
                    variant="standard" 
                    name="partnerInvolvement"
                    fullWidth
                    value={input.involvement}
                    onChange={(event) => handleInvolvementChange(event, index)}
                  />
                </Col>
              </Row>
            </div>
          ))}
          <Row className='mb-3'>
            <Col>
              <div>
                <Button onClick={handleAddPartner} >Adicionar Novo Parceiro</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ActionButtons {...props} nextStep={validate3} />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const Four = (props) => {

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <Container>
      <Card>
        <CardBody className="p-3 m-3">
          <h3>Confirme os dados</h3>
          <br />
          <Row>
            <h4>Dados do Projecto</h4>
            <Row>
              <Col>
                Titulo : <strong>{props.user.title}</strong>
              </Col>
              <Col>
                Cordenador : <strong>{props.user.coordinator}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Tipo : <strong>{props.user.type}</strong>
              </Col>
              <Col>
                Data De Início : <strong>{props.user.startDate}</strong>
              </Col>
              <Col>
                Data De Térmmino : <strong>{props.user.endDate}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                Valor do Projecto : <strong>{props.user.amount+" "+props.user.currency}</strong>
              </Col>
              <Col className={props.user.type === "Extensão" ? "" : "d-none"}>
                Local de implementação : <strong>{props.user.location}</strong>
              </Col>
            </Row>
            {
              props.user?.unities?.map((unity) => (
                <Row>
                <Col>
                  Unidade envolvida : <strong>{unity.unity.name}</strong>
                </Col>
                <Col>
                  Papel desempenhado : <strong>{unity.involvement}</strong>
                </Col>
                </Row>
              ))
            }
          </Row>
          <br />
            <h4>Financiamento</h4>
            {
              props.user?.financiers?.map((financier) => (
                <Row>
                  <Row>
                    <Col>
                      Financiador : <strong>{financier.financier.name}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Valor : <strong>{financier.amount+" "+financier.currency}</strong>
                    </Col>
                  </Row>
                </Row>
              ))
            }
          <br />
            <h4>Parceria</h4>
            {
              props.user?.partners?.map((partner) => (
                <Row>
                  <Col>
                    Parceiro : <strong>{partner.partner.name}</strong>
                  </Col>
                  <Col>
                    Envolvimento : <strong>{partner.involvement}</strong>
                  </Col>
                </Row>
              ))
            }
        </CardBody>
      </Card>
    <br />
    <ActionButtons {...props} lastStep={handleLastStep} />
    </Container>
  );
}

const ProjectStepper = () => {

  const auth = useAuthHeader()

  const [unities, setUnities] = useState([]);
  const [financiers, setFinanciers] = useState([]);
  const [partners, setPartners] = useState([]);

  const getUnities = async () => {
    
    const res = await api.get(`unities`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setUnities(res.data.unities)
  }

  const getFinanciers = async () => {
    
    const res = await api.get(`financiers`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setFinanciers(res.data.financiers)
  }

  const getPartners = async () => {
    
    const res = await api.get(`partners`,
    {
      headers: {
        Authorization: auth()
      }
    },
    {
      withCredentials: true
    })
    setPartners(res.data.partners)
  }

  useEffect(() => {
    getUnities();
    getFinanciers();
    getPartners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  const [, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignUser = (val) => {
    setUser((user) => ({
      ...user,
      ...val
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleComplete = async () => {
    setLoading(true)
    try {
      await api.post('project', {
        title: user.title,
        type: user.type,
        start_date: user.startDate,
        end_date: user.endDate,
        total_amount: parseFloat(user.amount),
        currency: user.currency,
        location: user.location,
        coordinator: user.coordinator,
        unities :
          user?.unities?.map((unity) => (
            {
              id: parseInt(unity.unity.id),
              involvement: unity.involvement
            }
          )),
        partners :
          user?.partners?.map((partner) => (
            {
              id: parseInt(partner.partner.id),
              involvement: partner.involvement
            }
          )),
        financiers :
          user?.financiers?.map((financier) => (
            {
              id: parseInt(financier.financier.id),
              amount: parseFloat(financier.amount),
              currency: financier.currency
            }
          ))},
        {
          headers: {
            Authorization: auth()
          },
          withCredentials: true,
          xsrfHeaderName: 'X-XSRF-TOKEN',
        }
      )
      navigate('/projects')
      setLoading(false)
      setMessage("Projecto Adicionado com Sucesso");
      setShowAlert(true);
    }catch(error){
      setLoading(false);
      if(error.response){
        setMessage("Erro ao tentar Salvar");
        setShowAlert(true);
      }
    }
  };

  return (
   <>
    {
        loading ? (
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress color="success" /> 
          </Container>
        ) : (
          <Container>
            <MyAlert
              showAlert={showAlert}
              handleCloseAlert={handleCloseAlert}
              message={message}
            />
            <Stepper activeStep={activeStep}>
              <Step label="Dados Do Projecto" children={<SummarizeIcon />} className={activeStep === 0 ? "bg-success" : ""} />
              <Step label="Financiadores" children={<Paid />} className={activeStep === 1 ? "bg-success" : ""} />
              <Step label="Parceiros" children={<Handshake />} className={activeStep === 2 ? "bg-success" : ""}/>
              <Step label="Confirmação" children={<Check/>} className={activeStep === 3 ? "bg-success" : ""} />
            </Stepper>
            <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
              <One userCallback={assignUser} unities={unities}/>
              <Two user={user} userCallback={assignUser} financiers={financiers}/>
              <Three user={user} userCallback={assignUser} financiers={financiers} partners={partners}/>
              <Four user={user} completeCallback={handleComplete}/>
            </StepWizard>
          </Container>
        )
    }
   </>
  );
};

export default ProjectStepper;
