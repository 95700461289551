import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

export default function Loading() {
  return (
    <Stack className='py-6' sx={{ width: '100%', color: 'grey.500' }} spacing={5}>
      <LinearProgress color="inherit" />
      <LinearProgress color="inherit" />
      <LinearProgress color="inherit" />
    </Stack>
  );
}