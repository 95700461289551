import React from 'react'
import Search from '../Search'
import CollapsibleTable from './CollapsibleTable'
import FloatingAddButton from '../../utils/FloatingAddButton'
import { useState } from 'react'

const List = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title= {"Pesquise por unidade, tipo ou título do projecto"} onSearch={handleSearch}/>
      <CollapsibleTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle="Adicionar novo Projecto" link='new'/>
    </>
  )
}

export default List