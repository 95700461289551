export const getValidationClassName = (userData, row) => {
    const roleId = userData().data?.roles[0].id;
  
    if (roleId === 1 || (roleId === 4) ||
        (row.validation && row.validation2) ||
        (roleId === 2 && row.validation) ||
        (roleId === 3 && row.validation2) ||
        (roleId === 2 && !row.validation2)) {
      return 'd-none';
    } else {
      return '';
    }
  };
  