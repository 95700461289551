import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Routes, Route, HashRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import { AuthProvider } from 'react-auth-kit';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <AuthProvider authType={'cookie'} authName={'auth'} cookieDomain={window.location.hostname} cookieSecure={false}>
    <HashRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes> 
    </HashRouter>
  </AuthProvider>
);