import React from 'react'
import Title from '../components/dashContent/Title'
import { Outlet } from 'react-router-dom';

const Partners = () => {
  return (
    <div className='m-1'>
        <Title text="Parceiros"/>
        <Outlet>
        
      </Outlet>
    </div>
  )
}

export default Partners