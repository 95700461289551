import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';


export default function Search({title, onSearch}) {

  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    onSearch(searchText);
  };

  return (
    <div className="d-flex justify-content-center">
    <Paper
      component="form"
      className="mb-2 d-flex align-items-center"
      style={{
        maxWidth: '50%',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={title}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
    </div>
  );
}