import React from 'react'
import { Card, Form, Label, FormGroup, CardBody, CardTitle, Input} from 'reactstrap';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, CardHeader } from 'reactstrap';
import { CDBBtn } from 'cdbreact';
import MyAlert from '../utils/MyAlert';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../services/Api';
import { useSignIn } from 'react-auth-kit';
import ChangePassword from './ChangePassword';


const Login = () => {

  const signIn = useSignIn();

  const [email, setEmail] = useState('') ;
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Cabeçalho");
  const [variant, setVariant] = useState("info");

  const [callModal, setCallModal] = useState(password === '12345678');

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [setOpenDialog] = useState(false)

  const handleOpenDialog = (openDialog) => {
    setOpenDialog(openDialog);
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      const response = await api.post(`/login`,
        {email , password}, 
      );
      response.data.token && signIn({token : response.data.token, authState : {data : response.data.user}, tokenType : 'Bearer', expiresIn: 43800 })
      setLoading(false);
      if (response?.data?.user && password === '12345678') {
        setCallModal(true);
      }else
        navigate(from, {replace: true});
    } catch(error){
      setLoading(false);
      if(error.response){
        if(error.response.status === 401){
          setMessage("Credenciais não reconhecidas");
          setVariant("info");
          setHeading("Dados Inexistentes");
          setShowAlert(true)
        }else{
          setMessage("Nao foi possivel conectar ao servidor, tente novamente mais tarde");
          setVariant("warning");
          setHeading("Erro no Servidor");
          setShowAlert(true)
        }
      }else{
        setMessage("Erro Desconhecido");
        setVariant("danger");
        setHeading("Um erro desconhecido foi encontrado, tente contactar um técnico");
        setShowAlert(true)
      }
    }
  }

  return (
    <>
    {
      loading ? (  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress color="success" /> 
        </div> ) :
      (
        <Container style={{ height: "100vh" }} className='d-flex justify-content-center align-items-center'>
      <Card className="border-0 bg-light" style={{ maxWidth: '500px' }}>
      <MyAlert
        showAlert={showAlert}
        handleCloseAlert={handleCloseAlert}
        severity={variant}
        heading={heading}
        message={message}
      />
      {callModal && (
        <div className='d-none'>
          <ChangePassword handleOpenDialog={handleOpenDialog} initialOpen={callModal} />
        </div>
      )}
        <CardHeader className='border-0 mt-4 m-2 p-1 text-center text-success'>SISTEMA DE APOIO À INVESTIGAÇÃO E EXTENSÃO</CardHeader>

        <CardTitle className='border-0 text-center text-success p-4'>
          Caro Investigador o sistema em questão existe para auxiliar na documentação de eventos e projectos decorridos, assim como na divulgação de conteúdo científico da Faculdade de Ciências e por fim dar relatórios precisos sobre as actividades, projectos, eventos e trabalhos que tenham sido executados!
          <br />
          <br />
          Insira suas credênciais para aceder ao sistema, em caso de dúvida contacte o administrador do sistema a partir de administracaoexemplo@uem.ac.mz 
         </CardTitle>
        <CardBody>
          <Form onSubmit={handleSubmit} className="row px-3">
            <FormGroup className="mb-3">
              <Label htmlFor="email"> 
                Endereço de e-mail
              </Label>
              <Input 
                type="email" 
                placeholder="Insira e-mail" 
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                className="rounded-3 border-0.1"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label htmlFor="password"> Senha</Label>
              <Input 
                type="password" 
                placeholder="Senha" 
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              value={password}
                required
                className="rounded-3 border-0.1"
              />
            </FormGroup>
            <div className='text-center pb-3'>
              <div className='text-center pb-3 d-flex justify-content-center'>
              <CDBBtn outline color="success" type='submit'>
                Entrar
              </CDBBtn>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
      )
    }
    </>
  )
}

export default Login