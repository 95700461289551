import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';


const SupervisorsForm = ({supervisor}) => {

  const auth = useAuthHeader()
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Alerta");
  const [severity, setSeverity] = useState("info");

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [name, setName] = useState(supervisor?.name || '');
  const [lastName, setLastName] = useState(supervisor?.last_name || '');
  const [type, setType] = useState(supervisor?.type || '');
  const [email, setEmail] = useState(supervisor?.email || '');
  const [academicLevel, setAcademicLevel] = useState(supervisor?.academic_level || '');

  const academiclevels = [
    {id : '1', name : 'Mestrado'},
    {id : '2', name : 'Licenciado'},
    {id : '3', name : 'Doutorado'},
    {id : '4', name : 'Bacharelato'},
    {id : '5', name : 'Catedrático'}
  ]

  const handleSave = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.post('supervisor', {
        name, 
        last_name : lastName,
        type,
        email,
        academic_level : academicLevel
      },
      {
        headers: {
            Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("Supervisor Adicionado com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/supervisors')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }

  const handleEdit = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.put(`supervisor/${supervisor.id}`, {
        name, 
        last_name : lastName,
        type,
        email,
        academic_level : academicLevel
      },
      {
        headers: {
            Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("alterações efectuadas com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/supervisors')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }


  return (
    loading ? (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress color="success" /> 
      </Container>
    ) : (
    <Form style={{ height: "50vh" }} className='d-flex justify-content-center align-items-center'>
      <MyAlert
        showAlert={showAlert}
        handleCloseAlert={handleCloseAlert}
        message={message}
        heading={heading}
        severity={severity}
      />
      <Card >
        <CardBody className='p-3'>
          <Row>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Primeiro Nome" 
              variant="standard" 
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Apelido" 
              variant="standard" 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col className='mb-3'>
            <FormControl fullWidth variant="standard">
              <InputLabel>Tipo de Supervisor</InputLabel>
              <Select
                label="Estudante Autor"
                onChange={(e) => setType(e.target.value)}
                name="supervisor"
              >
                <MenuItem value="Interno"> 
                  Interno
                </MenuItem>
                <MenuItem value="Externo"> 
                  Externo
                </MenuItem>
              </Select>
            </FormControl>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 200 }}>
              <InputLabel>Nivel Académico</InputLabel>
              <Select
                label="Moeda"
                onChange={(e) => setAcademicLevel(e.target.value)}
                value={academicLevel}
                >
                {
                    academiclevels.map((level, index) => (
                    <MenuItem value={level.name} id={index}> 
                        {level.name} 
                    </MenuItem>
                    ))
                }
              </Select>
              </FormControl>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                fullWidth type="email" 
                label="Email" 
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="text-end">
              <Col>
              {!supervisor?.name ? (
                <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
              ) : (
                <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
              )}
              </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
    )
  )
}

export default SupervisorsForm