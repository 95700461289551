import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';


const StudentsForm = ({student}) => {

  const auth = useAuthHeader()
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Alerta");
  const [severity, setSeverity] = useState("info");

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [name, setName] = useState(student?.name || '');
  const [lastName, setLastName] = useState(student?.last_name|| '');
  const [number, setNumber] = useState(student?.number || '');
  const [email, setEmail] = useState(student?.email || '');
  const [course, setCourse] = useState(student?.course_id || '');

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    
    const getCourses = async () => {
    
        const res = await api.get(`courses`,
        {
            headers: {
            Authorization: auth()
            }
        },
        {
            withCredentials: true
        })
        setCourses(res.data.courses)
    }

    getCourses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.post('student', {
        name, 
        last_name : lastName,
        number,
        email,
        course_id : course
      },
      {
        headers: {
            Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("Unidade Adicionada com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/students')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }

  const handleEdit = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.put(`student/${student.id}`, {
        name, 
        last_name : lastName,
        number,
        email,
        course_id : course
      },
      {
        headers: {
            Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setSeverity('info')
      setHeading('Editado')
      setMessage("Alterações Executadas com sucesso!");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/students');
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }

  return (
    loading ? (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress color="success" /> 
      </Container>
    ) : (
    <Form style={{ height: "50vh" }} className='d-flex justify-content-center align-items-center'>
      <MyAlert
        showAlert={showAlert}
        handleCloseAlert={handleCloseAlert}
        message={message}
        heading={heading}
        severity={severity}
      />
      <Card >
        <CardBody className='p-3'>
          <Row>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Primeiro Nome" 
              variant="standard" 
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Apelido" 
              variant="standard" 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col className='mb-3'>
            <FormControl fullWidth variant="standard">
              <InputLabel>Curso do Estudante</InputLabel>
              <Select
                label="Estudante Autor"
                onChange={(e) => setCourse(e.target.value)}
                name="student"
              >
                {
                  courses?.map((course, index) => (
                    <MenuItem value={course?.id}> 
                      {course.degree+" em "+course?.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
                <TextField 
                    fullWidth
                    type="number"
                    required
                    label="Numero de estudante" 
                    variant="standard" 
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                fullWidth type="email" 
                label="Email" 
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="text-end">
              <Col>
              {!student?.name ? (
                <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
              ) : (
                <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
              )}
              </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
    )
  )
}

export default StudentsForm