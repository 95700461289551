import React from 'react'
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    DropdownToggle,
} from 'reactstrap';
import { useAuthUser } from 'react-auth-kit'
import AccountMenu from './AccountMenu';

const Navmenu = () => {

    const auth = useAuthUser()    
  
    return (
    <div className=''>
        <Navbar color='success' className='m-1 p-3 rounded-3'>
            <NavbarBrand className='text-light' href="/">SAIE-UEM</NavbarBrand>
            <Nav>
                <NavItem  className='mt-1'>
                    <DropdownToggle nav className='text-light'>
                        {auth().data?.roles[0]?.name}
                    </DropdownToggle>
                </NavItem>
                <div>
                    <AccountMenu />
                </div>
            </Nav>
        </Navbar>   
    </div>
  )
}

export default Navmenu