import React from 'react'
import { Row, Col, List } from 'reactstrap'
import { CDBIcon } from 'cdbreact' 
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='bg-success rounded-3 p-4 m-1 text-light'>
        <Row>
            <Col md className='text-center mt-3 order-2 order-md-1'>
            <h5>Links Úteis</h5>
                <List type='unstyled'>
                    <li className='my-1'>
                        <a href="https://uem.mz" target="_blank" className='text-light' rel='noopener noreferrer'>Portal da UEM</a>
                    </li>
                    <li className='my-1'>
                        <a href="https://dsd.uem.mz" target="_blank" className='text-light' rel='noopener noreferrer'>Biblioteca Brazão Mazula</a>
                    </li>
                    <li className='my-1'>
                        <a href="https://ecampus.uem.mz" target="_blank" className='text-light' rel='noopener noreferrer'>Centro de Desenvolvimento Académico</a>
                    </li>
                </List>

            </Col>
            <Col md className='text-center order-1 order-md-1'>
                <strong className='mb-2'>Faculdade de Ciências da UEM</strong>
            </Col>
            <Col md className='text-center mt-3 order-3'>
                <p>
                <h5>Redes Sociais</h5>
                    <div className='m-1'>
                        <a href="https://www.facebook.com/cienciasuemmz" target="_blank" className='text-light' rel='noopener noreferrer'>
                            <CDBIcon fab spin icon='facebook'/> Facebook
                        </a>
                    </div>
                    <div className='m-1'>
                        <a href="https://www.twitter.com/@cienciasuem" target="_blank" className='text-light' rel='noopener noreferrer'>
                            <CDBIcon fab spin icon='twitter'/> Twitter
                        </a>
                    </div>
                    <div className='m-1'>
                        <a href="https://www.youtube.com/uemmoz" target="_blank" className='text-light' rel='noopener noreferrer'>
                            <CDBIcon fab spin icon='youtube'/> Youtube
                        </a>
                    </div>
                </p>
            </Col>
        </Row>
        <footer className='text-center'>
            <small>&copy; Copyright {new Date().getFullYear()}, Universidade Eduardo Mondlane</small>
        </footer>
    </div>
  )
}

export default Footer