import React from 'react';
import './forbidden.css';

const NotFound = () => {
  return (
    <div>
      <div class="maincontainer">
        <div class="bat">
          <img class="wing leftwing" 
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-wing.png"
               alt="bat"
          />
          <img class="body"
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-body.png"
               alt="bat"
          />
          <img class="wing rightwing"
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-wing.png"
               alt="bat"
          />
        </div>
        <div class="bat">
          <img class="wing leftwing" 
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-wing.png"
               alt="bat"
          />
          <img class="body"
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-body.png"
               alt="bat"
          />
          <img class="wing rightwing"
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-wing.png"
               alt="bat"
          />
        </div>
        <div class="bat">
          <img class="wing leftwing" 
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-wing.png"
               alt="bat"
          />
          <img class="body"
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-body.png"
               alt="bat"
          />
          <img class="wing rightwing"
               src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/bat-wing.png"
               alt="bat"
          />
        </div>
        <img class="foregroundimg"
             src="https://aimieclouse.com/Media/Portfolio/Error403Forbidden/HauntedHouseForeground.png"
             alt="haunted house"
        />
      </div>
      <h1 class="errorcode">ERRO 404</h1>
      <div class="errortext">Página não encontrada!</div>
    </div>
  );
};

export default NotFound;
