import { CircularProgress, TextField } from '@mui/material'
import React, {useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import api from '../../services/Api'
import { useAuthHeader } from 'react-auth-kit';
import MyAlert from '../../utils/MyAlert'
import { useNavigate } from 'react-router-dom';


const UnitiesForm = ({unity}) => {

  const auth = useAuthHeader()
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Mensagem");
  const [heading, setHeading] = useState("Alerta");
  const [severity, setSeverity] = useState("info");

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const [name, setName] = useState(unity?.name || '');
  const [phone, setPhone] = useState(unity?.phone || '');
  const [fax, setFax] = useState(unity?.fax || '');
  const [email, setEmail] = useState(unity?.email || '');

  const handleSave = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.post('unity', {
        name, 
        phone,
        fax,
        email,
      },
      {
        headers: {
          Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setLoading(false)
      setSeverity('success')
      setHeading('Guardado')
      setMessage("Unidade Adicionada com Sucesso");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/unities')
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }

  const handleEdit = async (e) => {

    e.preventDefault();

    setLoading(true);

    try {
      await api.put(`unity/${unity.id}`, {
        name, 
        phone,
        fax,
        email,
      },
      {
        headers: {
          Authorization : auth()
        },
        withCredentials: true,
        xsrfHeaderName: 'X-XSRF-TOKEN',
      })
      setSeverity('info')
      setHeading('Editado')
      setMessage("Alterações Executadas com sucesso!");
      setShowAlert(true);
      setTimeout(() => {
        navigate('/unities');
      }, 2500);
    }catch(error){
      setLoading(false);
      if(error.response){
        setHeading("Erro")
        setSeverity("error")
        setMessage(error.message);
        setShowAlert(true);
      }
    }
  }


  return (
    loading ? (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress color="success" /> 
      </Container>
    ) : (
    <Form style={{ height: "50vh" }} className='d-flex justify-content-center align-items-center'>
      <MyAlert
        showAlert={showAlert}
        handleCloseAlert={handleCloseAlert}
        message={message}
        heading={heading}
        severity={severity}
      />
      <Card >
        <CardBody className='p-3'>
          <Row>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Nome da Unidade" 
              required
              variant="standard" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col md={6}>
              <TextField 
              fullWidth
              label="Telefone" 
              variant="standard" 
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                fullWidth type="email" 
                label="Email" 
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <TextField 
                fullWidth
                label="Fax" 
                variant="standard" 
                value={fax}
                onChange={(e) => setFax(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="text-end">
            <Col>
              {!unity?.name ? (
                <Button className="bg-success" type='submit' onClick={handleSave}> Guardar </Button>
              ) : (
                <Button className="bg-success" type='submit' onClick={handleEdit} > Salvar Alterações </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
    )
  )
}

export default UnitiesForm