import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Login from './components/Login';
import RegisterStepper from './components/RegisterStepper';
import Home from './pages/Home';
import Sidebardash from './components/layout/Sidebardash';
import Projects from './pages/Projects';
import Articles from './pages/Articles';
import Works from './pages/Works';
import Events from './pages/Events';
import Reports from './pages/Reports';
import Unities from './pages/Unities';
import Financiers from './pages/Financiers';
import Partners from './pages/Partners';
import ProjectStepper from './components/projects/ProjectStepper';
import ProjectsList from './components/projects/ProjectsList';
import RequireAuth from './hooks/RequireAuth';
import "./App.css"
import ArticlesList from './components/articles/ArticlesList';
import ArticleStepper from './components/articles/ArticleStepper';
import EventsList from './components/events/EventsList';
import EventStepper from './components/events/EventStepper';
import WorksList from './components/works/WorksList';
import WorkStepper from './components/works/WorkStepper';
import Autors from './pages/Autors';
import AutorsList from './components/autors/AutorsList';
import AutorsForm from './components/autors/AutorsForm';
import FinanciersList from './components/financiers/FinanciersList';
import FinanciersForm from './components/financiers/FinanciersForm';
import UnitiesList from './components/unities/UnitiesList';
import UnitiesForm from './components/unities/UnitiesForm';
import PartnersList from './components/partners/PartnersList';
import PartnersForm from './components/partners/PartnersForm';
import CoursesList from './components/courses/CoursesList';
import CoursesForm from './components/courses/CoursesForm';
import Courses from './pages/Courses';
import Students from './pages/Students';
import Supervisors from './pages/Supervisors';
import SupervisorsForm from './components/supervisors/SupervisorsForm';
import SupervisorsList from './components/supervisors/SupervisorsList';
import StudentsForm from './components/students/StudentsForm';
import StudentsList from './components/students/StudentsList';
import ReportsData from './components/reports/ReportsData';
import UnitiesEdit from './components/unities/UnityEdit';
import FinancierEdit from './components/financiers/FinancierEdit';
import AutorEdit from './components/autors/AutorEdit';
import PartnerEdit from './components/partners/PartnerEdit';
import CourseEdit from './components/courses/CourseEdit';
import StudentEdit from './components/students/StudentEdit';
import SupervisorsEdit from './components/supervisors/SupervisorsEdit';
import WorksEdit from './components/works/WorksEdit';
import Users from './pages/Users';
import UsersList from './components/users/UsersList';
import UsersForm from './components/users/UsersForm';
import UserEdit from './components/users/UserEdit';
import RequireAdmin from './hooks/RequireAdmin';
import ForbiddenAdmin from './hooks/ForbiddenAdmin';
import Forbidden from './pages/Forbidden';
import NotFound from './pages/NotFound';


function App() {
  return (
    <Routes> 

      <Route path="login" element = { <Login/> }/>
      <Route path="register" element = { <RegisterStepper/> }/>

      <Route element={<RequireAuth />}>
        
        <Route path='/' element = { <Sidebardash/> }>

          <Route index element = { <Home/> }/>

          <Route path="unities" element ={ <Unities/> }>
            <Route index element = {<UnitiesList/>} />
            <Route path='new' element = { <UnitiesForm/> } />
            <Route path='edit' element={<UnitiesEdit  />} />
          </Route>

          <Route path="courses" element ={ <Courses/> }>
            <Route index element = {<CoursesList/>} />
            <Route path='new' element = { <CoursesForm/> } />
            <Route path='edit' element={<CourseEdit  />} />
          </Route>

          <Route element={<RequireAdmin/>}>

            <Route path="users" element={<Users/>}>

              <Route index element={<UsersList />} />
              <Route path='new' element={<UsersForm />} />
              <Route path='edit' element={<UserEdit />} />
            </Route>
          </Route>

          <Route element={<ForbiddenAdmin/>}>
            
            <Route path="articles" element ={ <Articles/> }>
              <Route index element = { <ArticlesList/> } /> 
              <Route path='new' element = { <ArticleStepper/> } />
            </Route>

            <Route path="projects" element ={ <Projects/> }>
              <Route index element = { <ProjectsList/> } />
              <Route path='new' element = { <ProjectStepper/> } />
            </Route>

            <Route path='events' element={<Events/>}>
              <Route index element = { <EventsList/> } />
              <Route path='new' element = { <EventStepper/> } />
            </Route>

            <Route path="works" element ={ <Works/> }>
              <Route index element = { <WorksList/> } />
              <Route path='new' element = { <WorkStepper/> } />
              <Route path='edit' element ={ <WorksEdit/> } />
            </Route>

            <Route path="autors" element ={ <Autors/> } >
              <Route index element = {<AutorsList/>} />
              <Route path='new' element = { <AutorsForm/> } />
              <Route path='edit' element={<AutorEdit  />} />
            </Route>

            <Route path="financiers" element ={ <Financiers/> }>
              <Route index element = {<FinanciersList/>} />
              <Route path='new' element = { <FinanciersForm/> } />
              <Route path='edit' element={<FinancierEdit  />} />
            </Route>
          
            <Route path="partners" element ={ <Partners/> }>
              <Route index element = {<PartnersList/>} />
              <Route path='new' element = { <PartnersForm/> } />
              <Route path='edit' element={<PartnerEdit  />} />
            </Route>
            <Route path="students" element ={ <Students/> }>
              <Route index element = {<StudentsList/>} />
              <Route path='new' element = { <StudentsForm/> } />
              <Route path='edit' element={<StudentEdit  />} />
            </Route>
            <Route path="supervisors" element ={ <Supervisors/> }>
              <Route index element = {<SupervisorsList/>} />
              <Route path='new' element = { <SupervisorsForm/> } />
              <Route path='edit' element={<SupervisorsEdit  />} />
            </Route>

            <Route path="reports" element ={ <Reports/> }>
              <Route index element ={<ReportsData/>}/>
            </Route>
          </Route>
          
          <Route path='forbidden' element={<Forbidden/> }/>
          <Route path='*' element={<NotFound/> }/>

        </Route>
      </Route>
    </Routes>    
  );
}

export default App;