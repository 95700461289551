import React from 'react'
import CoursesForm from './CoursesForm'
import { useLocation } from 'react-router-dom';

const CourseEdit = () => {
    const location = useLocation();
    const course = location.state?.course;
  
    return <CoursesForm course={course} />;
}

export default CourseEdit