import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import CoursesTable from './CoursesTable'
import { useState } from 'react';

const CoursesList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title={"Pesquise o nome do Curso"} onSearch={handleSearch}/>
      <CoursesTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle={"Adicionar Novo Curso"} link={"new"}/>
    </>
  )
}

export default CoursesList 