import React from 'react'
import { useLocation } from 'react-router-dom'
import WorkStepper from './WorkStepper'


const WorksEdit = () => {
  const location = useLocation();
  const work = location.state?.work;

  return <WorkStepper work={work} />;
}

export default WorksEdit