import React from 'react'
import Search from '../Search'
import FloatingAddButton from '../../utils/FloatingAddButton';
import SupervisorsTable from './SupervisorsTable'
import { useState } from 'react';

const SupervisorsList = () => {

  const [searchKey, setSearchKey] = useState('')

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
  };

  return (
    <>
      <Search title={"Pesquise o nome do Supervisor"} onSearch={handleSearch}/>
      <SupervisorsTable searchKey={searchKey}/>
      <FloatingAddButton toolTitle={"Adicionar Novo Supervisor"} link={"new"}/>
    </>
  )
}

export default SupervisorsList 