import { Link } from "react-router-dom";
import './sidebaritem.css'
import { useState } from "react";
import { CDBSidebarMenuItem } from "cdbreact";
import './sidebaritem.css'

const SidebarSubItem = ({ text, iname, link }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
      className="padder"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
        <Link to={link}>
            <CDBSidebarMenuItem className="mx-4 px-3" iconClassName={ isHovered && 'text-success'} icon={iname} iconSize='sm' >
            <div className={isHovered ? 'text-success' : ''}>
                {text}
            </div>
            </CDBSidebarMenuItem>
        </Link>
      </div>
    );
  };

  export default SidebarSubItem;